import { Currency } from '@/sdk/entities/currency';
import { ChainId } from '@/sdk/constants';
import { compareTokenAddresses, validateAndParseAddress } from '@/sdk/utils';
import { isCardanoMainnetOrTestnet } from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import invariant from 'tiny-invariant';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { NETWORK_ETH_TOKEN_NAME, NETWORK_WETH_TOKEN_NAME } from '@/helpers/network-eth-token';
import { ENABLE_CROSS_CHAIN } from '@/helpers/crossChainEnv.helper';

const cardanoNativeAddress = 'lovelace';

/**
 * Types of token relative of chain.
 *
 * GAS   - GAS token in chain
 * WGAS  - Wrapped GAS token in chain
 * ERC20 - regular token in chain
 */
export type TokenType = 'GAS' | 'WGAS' | 'ERC20';

export type ITokenDescription = {
  tokenType: TokenType;
  crossChainSymbol: string;
  isPresentLocally: boolean;
  gasToken?: Token;
};

export class Token extends Currency {
  public readonly address: string;
  public readonly chainId: ChainId;
  public readonly projectLink?: string;
  public readonly tokenIconUrl?: string;
  public readonly description?: Readonly<ITokenDescription>;

  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    projectLink?: string,
    tokenIconUrl?: string,
    description?: ITokenDescription,
  ) {
    super(decimals, symbol, name);
    this.chainId = chainId;
    if (!isCardanoMainnetOrTestnet(chainId)) {
      this.address = validateAndParseAddress(address);
    } else {
      this.address = address;
    }
    this.projectLink = projectLink;

    // HACK: need fixed
    if (
      this.symbol?.toUpperCase() === 'MAX' &&
      [ChainId.BOB_CHAIN_MAINNET, ChainId.BOB_CHAIN_TESTNET].includes(chainId)
    ) {
      this.tokenIconUrl = '/images/tokens/max_bob.svg';
    } else {
      this.tokenIconUrl = tokenIconUrl;
    }

    this.description = description;
  }

  get crossChainSymbol(): string | undefined {
    if (!this.description) return this.symbol;

    return this.description.crossChainSymbol;
  }

  public equals(other: Token): boolean {
    if (this === other) {
      return true;
    }

    if (ENABLE_CROSS_CHAIN && this.chainId !== other.chainId) {
      return this.equalsBySymbol(other);
    }

    return this.chainId === other.chainId && this.address === other.address;
  }

  public equalsBySymbol(other: Token): boolean {
    if (this === other) {
      return true;
    }

    return this.unwrapWETH().symbol?.toLowerCase() === other.unwrapWETH().symbol?.toLowerCase();
  }

  public isSameSymbol(other: Token): boolean {
    if (this === other) {
      return true;
    }
    return (
      this.chainId === other.chainId &&
      this.equals(other) &&
      this.symbol?.toLowerCase() === other.symbol?.toLowerCase()
    );
  }

  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS');
    invariant(this.address !== other.address, 'ADDRESSES');
    if (
      this.symbol?.toUpperCase() === NETWORK_WETH_TOKEN_NAME?.toUpperCase() ||
      this.symbol?.toUpperCase() === NETWORK_ETH_TOKEN_NAME?.toUpperCase()
    ) {
      return true;
    } else if (
      other.symbol?.toUpperCase() === NETWORK_WETH_TOKEN_NAME?.toUpperCase() ||
      this.symbol?.toUpperCase() === NETWORK_ETH_TOKEN_NAME?.toUpperCase()
    ) {
      return false;
    }
    return this.address.toLowerCase() < other.address.toLowerCase();
  }

  /**
   * @returns {boolean} is GAS token in chain (token chain)
   */
  isBaseToken(): boolean {
    if (!this.description) return false;

    return this.description.tokenType === 'WGAS' || this.description.tokenType === 'GAS';
  }

  /**
   * @returns {boolean} is Wrapped GAS token in current chain (current app chain)
   */
  isWETHToken(): boolean {
    if (!this.description) return false;

    return this.description.tokenType === 'WGAS' && this.chainId.toString() === DEFAULT_NETWORK_ID;
  }

  /**
   * @returns {boolean} is GAS token in current chain (current app chain)
   */
  isETHToken(): boolean {
    if (!this.description) return compareTokenAddresses(cardanoNativeAddress, this.address);

    return (
      (this.description.tokenType === 'GAS' && this.chainId.toString() === DEFAULT_NETWORK_ID) ||
      compareTokenAddresses(cardanoNativeAddress, this.address)
    );
  }

  /**
   * @returns {Token} GAS token in current chain (current app chain) or itself token
   */
  unwrapWETH(): Token {
    if (this.isETHToken()) return this;

    return this.description && this.description.gasToken ? this.description.gasToken : this;
  }
}

export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB);
  } else if (currencyA instanceof Token) {
    return false;
  } else if (currencyB instanceof Token) {
    return false;
  } else {
    return currencyA === currencyB;
  }
}

export const WETH = {
  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org',
    '/images/tokens/bnb.svg',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xaE8E19eFB41e7b96815649A6a60785e1fbA84C1e',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org',
    '/images/tokens/bnb.svg',
  ),
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: new Token(
    ChainId.MILKOMEDA_CARDANO_TESTNET,
    '0x1a40217B16E7329E27FDC9cED672e1F264e07Cc2',
    18,
    'WADA',
    'Wrapped ADA',
    'https://milkomeda.com',
    '/images/tokens/ada.svg',
  ),
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: new Token(
    ChainId.MILKOMEDA_CARDANO_MAINNET,
    '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9',
    18,
    'WADA',
    'Wrapped ADA',
    'https://milkomeda.com',
    '/images/tokens/ada.svg',
  ),
  [ChainId.MILKOMEDA_ALGORAND_TESTNET]: new Token(
    ChainId.MILKOMEDA_ALGORAND_TESTNET,
    '0xb8f56715F3006A010025946351c9e5C30A0797bF',
    18,
    'WALGO',
    'Wrapped ALGO',
    'https://milkomeda.com',
    '/images/tokens/algo.svg',
  ),
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: new Token(
    ChainId.MILKOMEDA_ALGORAND_MAINNET,
    '0xaF86E6c5Fd9dAf53e5100ed38BaB2572609fCA27',
    18,
    'WALGO',
    'Wrapped ALGO',
    'https://milkomeda.com',
    '/images/tokens/algo.svg',
  ),
  [ChainId.KAVA_TESTNET]: new Token(
    ChainId.KAVA_TESTNET,
    '0xFa95D53e0B6e82b2137Faa70FD7E4a4DC70Da449',
    18,
    'WKAVA',
    'Wrapped KAVA',
    'https://www.kava.io/',
    '/images/tokens/kava.svg',
  ),
  [ChainId.KAVA_MAINNET]: new Token(
    ChainId.KAVA_MAINNET,
    '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    18,
    'WKAVA',
    'Wrapped KAVA',
    'https://www.kava.io/',
    '/images/tokens/kava.svg',
  ),
  [ChainId.POLYGON_TESTNET]: new Token(
    ChainId.POLYGON_TESTNET,
    '0xd59FCF6307C807D496cDD2b5fDD95ecbE5315E56',
    18,
    'WMATIC',
    'Wrapped Matic',
    'https://polygon.technology/',
    '/images/tokens/matic.svg',
  ),
  [ChainId.POLYGON_MAINNET]: new Token(
    ChainId.POLYGON_MAINNET,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped Matic',
    'https://polygon.technology/',
    '/images/tokens/matic.svg',
  ),
  [ChainId.NEON_TESTNET]: new Token(
    ChainId.NEON_TESTNET,
    '0x11adC2d986E334137b9ad0a0F290771F31e9517F',
    18,
    'WNEON',
    'Wrapped Neon',
    'https://neon-labs.org/',
    '/images/tokens/neon.svg',
  ),
  [ChainId.BERA_CHAIN_TESTNET]: new Token(
    ChainId.BERA_CHAIN_TESTNET,
    '0x5806E416dA447b267cEA759358cF22Cc41FAE80F',
    18,
    'WBERA',
    'Wrapped BERA',
    'https://www.berachain.com/',
    '/images/tokens/bera.svg',
  ),
  [ChainId.ETH_SEPOLIA_TESTNET]: new Token(
    ChainId.ETH_SEPOLIA_TESTNET,
    '0xf531B8F309Be94191af87605CfBf600D71C2cFe0',
    18,
    'WETH',
    'Wrapped ETH',
    'https://ethereum.org/',
    '/images/tokens/eth.svg',
  ),
  [ChainId.ARBITRUM_TESTNET]: new Token(
    ChainId.ARBITRUM_TESTNET,
    '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73',
    18,
    'WETH',
    'Wrapped ETH',
    'https://arbitrum.io/',
    '/images/tokens/eth.svg',
  ),
  [ChainId.BOB_CHAIN_TESTNET]: new Token(
    ChainId.BOB_CHAIN_TESTNET,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH',
    'https://www.gobob.xyz/',
    '/images/tokens/eth.svg',
  ),
  [ChainId.BOB_CHAIN_MAINNET]: new Token(
    ChainId.BOB_CHAIN_MAINNET,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH',
    'https://www.gobob.xyz/',
    '/images/tokens/eth.svg',
  ),
};
