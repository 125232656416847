import { computed, ComputedRef, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import JSBI from 'jsbi';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { BN_ZERO, ChainId } from '@/sdk/constants';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Token } from '@/sdk/entities/token';
import multicall, { Call } from '@/utils/multicall';
import erc20 from '@/data/abi/erc20.json';
import { APP_NETWORK_NAME, DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { BIG_ZERO } from '@/utils/bigNumber';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { useTokens } from './useTokens';
import { CardanoWallet } from 'crypto-sdk';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { isCardanoNativeToken } from './utils';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export enum FetchStatus {
  NOT_FETCHED = 'not-fetched',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type TokenBalance = {
  balance: TokenAmount;
  status: FetchStatus;
};

export const useBalances = defineStore('balances', () => {
  const { walletState, getProviderBy } = useWallet();
  const { walletState: evmWalletState } = useEVMWallet();
  const { getTokensListByChainId } = useTokens();

  const balances = ref<Record<string, TokenBalance> | null>();

  const getTokensForCurrentChainId = (): Token[] => {
    if (!DEFAULT_NETWORK_ID) return [];
    return getTokensListByChainId(DEFAULT_NETWORK_ID as unknown as ChainId);
  };

  const getCardanoTokensForCurrentCardanoChainId = (): Token[] => {
    if (!DEFAULT_CARDANO_CHAIN_ID) return [];
    return getTokensListByChainId(DEFAULT_CARDANO_CHAIN_ID as unknown as ChainId);
  };

  const tokenBalanceWei = (symbol: string): ComputedRef<BigNumber> => {
    return computed(() => {
      const balance = balances.value?.[symbol];

      if (!balance) {
        return BIG_ZERO;
      }

      return new BigNumber(balance.balance.raw.toString());
    });
  };

  const tokenBalanceRelative = (symbol: string): ComputedRef<BigNumber> => {
    return computed(() => {
      const balance = balances.value?.[symbol];

      if (!balance) {
        return BIG_ZERO;
      }

      return new BigNumber(balance.balance.toExact());
    });
  };

  // TODO: getter from token module
  const getBalanceByToken = (token: Token): TokenBalance | null => {
    const balance = token.symbol ? balances.value?.[token.symbol] : null;
    if (!balance) return null;

    return balance;
  };

  // TODO: getter from token module
  const getBalancesWithFilter = (
    filter?: Token,
  ): Record<string, TokenBalance> | TokenBalance | null => {
    if (!balances.value || !Object.keys(balances.value).length) return null;
    if (filter && filter.symbol) return balances.value[filter.symbol];
    return balances.value;
  };

  const balanceByToken = (token: Token): ComputedRef<TokenBalance | null> => {
    return computed(() => {
      return getBalanceByToken(token);
    });
  };

  const balanceByTokenSymbolAndChainId = (
    symbol: string,
    chainId: ChainId,
  ): ComputedRef<TokenBalance | null> => {
    return computed(() => {
      let key = `${symbol}-${chainId}`;
      if (!ENABLE_FAKE_CARDANO_NETWORK && chainId.toString() === DEFAULT_NETWORK_ID!) {
        key = `${symbol}`;
      }
      const balance = balances.value?.[key];

      if (!balance) return null;

      return balance;
    });
  };

  function initTokenBalances() {
    const balancesList = {};
    getTokensForCurrentChainId().forEach(token => {
      const tokenBalance = {
        balance: new TokenAmount(token, BN_ZERO),
        status: FetchStatus.NOT_FETCHED,
      };
      balancesList[token.symbol!] = tokenBalance;
    });

    balances.value = balancesList;
  }

  async function updateTokenBalances(): Promise<void> {
    if (!walletState.isInjected || !walletState.isNetworkSupported) {
      return;
    }

    LOGGER.groupCollapsed('[BALANCES] updateTokenBalances');

    const { balances: balancesRequest, tokens: requestedTokens } = prepareBalancesAndTokens();

    const balancesList = balances.value || {};
    try {
      const balancesResponse = (await Promise.all(balancesRequest)).flat();

      const updatedBalancesList: Record<string, { balance: BigNumber; token: Token }> = {};
      balancesResponse.forEach((rawBalance, index) => {
        const token = requestedTokens[index];
        const [balanceValue] = [rawBalance].flat();
        LOGGER.groupCollapsed(
          `[BALANCE] ${token.symbol} [${token.chainId} | ${token.address} | ${token.decimals}] : `,
          balanceValue.toString(),
        );
        const balance = {
          balance: BigNumber(balanceValue.toString()),
          token: token,
        };

        if (ENABLE_FAKE_CARDANO_NETWORK) {
          if (updatedBalancesList[token.symbol!]) {
            const prevBalance = updatedBalancesList[token.symbol!];
            const prevToken = prevBalance.token;
            const curToken = balance.token;

            // set same decimals (decimals of prev tokens)
            if (prevToken.decimals !== curToken.decimals) {
              balance.balance = balance.balance.shiftedBy(prevToken.decimals - curToken.decimals);
            }

            // sum balances
            balance.token = prevToken;
            balance.balance = prevBalance.balance.plus(balance.balance);
          }
          updatedBalancesList[token.symbol!] = balance;

          const tokenBalanceInChain = {
            balance: new TokenAmount(token, JSBI.BigInt(balanceValue.toString())),
            status: FetchStatus.SUCCESS,
          };
          balancesList[`${token.symbol!}-${token.chainId}`] = tokenBalanceInChain;
          LOGGER.log(
            `Balance ${token.symbol} (${token.address}) in ${token.chainId} [TOKEN] : `,
            tokenBalanceInChain.balance.toExact(),
          );
        }

        const totalTokenBalance = {
          balance: new TokenAmount(balance.token, JSBI.BigInt(balance.balance.toString())),
          status: FetchStatus.SUCCESS,
        };
        balancesList[token.symbol!] = totalTokenBalance;
        LOGGER.log(
          `Summary balance ${balance.token.symbol} (${balance.token.address}) [TOKEN] : `,
          totalTokenBalance.balance.toExact(),
        );
        LOGGER.groupEnd();
      });
    } catch (ex) {
      console.error('[UPDATE:BALANCES] Error: ', ex);

      Object.entries(balancesList).forEach(([key, balance]) => {
        balancesList[key] = {
          balance: balance.balance,
          status: FetchStatus.FAILED,
        };
      });
    } finally {
      LOGGER.groupEnd();
    }
    balances.value = balancesList;
    loggingBalances(balances.value);
  }

  function prepareBalancesAndTokens() {
    let tokens: Token[] = [];
    let balances: Promise<ethers.BigNumber[][] | ethers.BigNumber>[] = [];

    const { tokens: evmTokens, balances: evmBalances } = prepareCallsBalancesAndTokensForEVM();
    tokens = [...evmTokens];
    balances = [...evmBalances];

    if (ENABLE_FAKE_CARDANO_NETWORK) {
      const { tokens: cardanoTokens, balances: cardanoBalances } =
        prepareCallsBalancesAndTokensForCardano();

      tokens = [...tokens, ...cardanoTokens];
      balances = [...balances, ...cardanoBalances];
    }

    return {
      tokens,
      balances,
    };
  }

  // EVM
  function prepareCallsBalancesAndTokensForEVM() {
    const tokens: Token[] = [];
    const gasToken: Token[] = [];
    const evmBaseTokenBalance: Promise<ethers.BigNumber>[] = [];

    const callsAccountBalances: Call[] = [];
    getTokensForCurrentChainId().forEach(token => {
      if (!ENABLE_FAKE_CARDANO_NETWORK && !token.description?.isPresentLocally) return;

      if (token.isETHToken()) {
        gasToken.push(token);
        return;
      }

      tokens.push(token);
      callsAccountBalances.push({
        address: token.address,
        name: 'balanceOf',
        params: [evmWalletState.account],
      });
    });

    const evmAccountBalances = multicall<ethers.BigNumber[][]>(erc20, callsAccountBalances);
    if (gasToken.length) {
      evmBaseTokenBalance.push(getEVMGasTokenBalance());
    }

    return {
      tokens: [...tokens, ...gasToken],
      balances: [evmAccountBalances, ...evmBaseTokenBalance],
    };
  }

  function getEVMGasTokenBalance(): Promise<ethers.BigNumber> {
    return getInstance().web3.getSigner().getBalance('latest');
  }
  // ---

  // Cardano
  function prepareCallsBalancesAndTokensForCardano() {
    const tokens: Token[] = [];
    const gasToken: Token[] = [];
    const balances: Promise<ethers.BigNumber>[] = [];

    getCardanoTokensForCurrentCardanoChainId().forEach(cardanoToken => {
      if (isCardanoNativeToken(cardanoToken.address)) {
        gasToken.push(cardanoToken);
        return;
      }

      tokens.push(cardanoToken);
      balances.push(getCardanoTokenBalance(cardanoToken.address));
    });
    balances.push(getCardanoGasTokenBalance());

    return {
      tokens: [...tokens, ...gasToken],
      balances,
    };
  }

  function getCardanoTokenBalance(address: string): Promise<ethers.BigNumber> {
    const cardanoWallet: CardanoWallet = getProviderBy(APP_NETWORK_NAME);
    return cardanoWallet.getBalanceToken(address).then(balance => ethers.BigNumber.from(balance));
  }

  function getCardanoGasTokenBalance(): Promise<ethers.BigNumber> {
    const cardanoWallet: CardanoWallet = getProviderBy(APP_NETWORK_NAME);
    return cardanoWallet.getBalance().then((balanceResponse: { quantity: string }[]) => {
      return ethers.BigNumber.from(balanceResponse[0].quantity);
    });
  }
  // ---

  watch(
    () => walletState.wallets[APP_NETWORK_NAME]?.account,
    account => {
      if (account) {
        updateTokenBalances();
      }
    },
    { immediate: true },
  );

  setInterval(updateTokenBalances, UPDATE_INTERVAL);

  return {
    balances,
    getBalancesWithFilter,
    getBalanceByToken,
    balanceByToken,
    balanceByTokenSymbolAndChainId,
    tokenBalanceWei,
    tokenBalanceRelative,
    initTokenBalances,
    updateTokenBalances,
  };
});

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].BALANCES;
}

function loggingBalances(balances: Record<string, TokenBalance> | null | undefined) {
  console.log(
    'BALANCES: ',
    Object.entries(balances ?? {}).reduce((acc, [key, tokenBalance]) => {
      acc[key] = {
        status: tokenBalance.status,
        balance: tokenBalance.balance.toExact(),
      };

      return acc;
    }, {}),
  );
}
