import { Token } from '@/sdk/entities/token';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import JSBI from 'jsbi';
import { sqrt } from '@/sdk/utils';
import invariant from 'tiny-invariant';
import { Price } from '@/sdk/entities/fractions/price';
import {
  BigintIsh,
  BN_ONE,
  BN_ZERO,
  ChainId,
  FEES_DENOMINATOR,
  FEES_NUMERATOR,
  MINIMUM_LIQUIDITY,
  EMPTY_PAIR_SOURCE,
  STABLE_SWAP_AMPLIFIER,
} from '@/sdk/constants';
import { InsufficientInputAmountError, InsufficientReservesError } from '@/sdk/errors';
import { Percent } from '@/sdk/entities/fractions/percent';
import { Fraction } from '@/sdk/entities/fractions/fraction';
import { parseUnits } from 'ethers/lib/utils';
import { PairSource, PairSourceType } from './pairSource';
import { getSwapInput, getSwapOutput } from '@/store/modules/swap/stableswap-calc-methods';

const FEE = new Percent(JSBI.subtract(FEES_DENOMINATOR, FEES_NUMERATOR), FEES_DENOMINATOR);

export class Pair {
  public readonly tokenAddress: string;
  public readonly liquidityToken: Token;
  private readonly tokenAmounts: [TokenAmount, TokenAmount];
  public totalSupply: TokenAmount;
  public poolTokensOwned: TokenAmount;
  public pairSource: PairSource;

  public constructor(
    tokenAddress: string,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount,
    totalSupply?: BigintIsh,
    poolTokensOwned?: BigintIsh,
    pairSource?: PairSource,
  ) {
    const tokenAmounts = [tokenAmountA, tokenAmountB];

    this.pairSource = pairSource ?? EMPTY_PAIR_SOURCE;

    this.liquidityToken = new Token(
      tokenAmounts[0].token.chainId,
      tokenAddress,
      18,
      'LQF-V2',
      'Liquifi V2',
    );
    this.tokenAddress = tokenAddress;
    this.tokenAmounts = tokenAmounts as [TokenAmount, TokenAmount];
    this.totalSupply = new TokenAmount(this.liquidityToken, totalSupply ?? BN_ZERO);
    // console.log(
    //   'totalSupply',
    //   tokenAddress,
    //   tokenAmounts[0].token.symbol,
    //   tokenAmounts[1].token.symbol,
    //   this.totalSupply.toExact(),
    // );
    this.poolTokensOwned = new TokenAmount(this.liquidityToken, poolTokensOwned ?? BN_ZERO);
    // console.log(
    //   'poolTokensOwned',
    //   tokenAddress,
    //   tokenAmounts[0].token.symbol,
    //   tokenAmounts[1].token.symbol,
    //   this.poolTokensOwned.toExact(),
    // );
  }

  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  public get token0Price(): Price {
    return new Price(this.token0, this.token1, this.tokenAmounts[0].raw, this.tokenAmounts[1].raw);
  }

  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  public get token1Price(): Price {
    return new Price(this.token1, this.token0, this.tokenAmounts[1].raw, this.tokenAmounts[0].raw);
  }

  /**
   * Returns the chain ID of the tokens in the pair.
   */
  public get chainId(): ChainId {
    return this.token0.chainId;
  }

  public get token0(): Token {
    return this.tokenAmounts[0].token;
  }

  public get token1(): Token {
    return this.tokenAmounts[1].token;
  }

  public get reserve0(): TokenAmount {
    return this.tokenAmounts[0];
  }

  public get reserve1(): TokenAmount {
    return this.tokenAmounts[1];
  }

  public get tokensOwnedPercent(): Percent {
    return new Percent(this.poolTokensOwned?.raw || BN_ZERO, this.totalSupply?.raw || BN_ONE);
  }

  //TODO TOComplete
  //

  public getPoolTokensReceived(amounts: TokenAmount[]): TokenAmount {
    console.warn('[DEPRECATED] getPoolTokensReceived');

    // const tokenAmounts = amounts[0].token.sortsBefore(amounts[1].token) // does safety checks
    //   ? [amounts[0], amounts[1]]
    //   : [amounts[1], amounts[0]];
    const tokenAmounts = [amounts[0], amounts[1]];

    const fromAmount = JSBI.toNumber(JSBI.divide(tokenAmounts[0].raw, this.reserve0.raw));
    const toAmount = JSBI.toNumber(JSBI.divide(tokenAmounts[1].raw, this.reserve1.raw));
    const minAmount = JSBI.BigInt(Math.min(fromAmount, toAmount));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new TokenAmount(this.liquidityToken, JSBI.multiply(this.totalSupply.raw, minAmount));
  }

  public get ownedAsset0(): TokenAmount {
    const fraction = new Fraction(
      JSBI.multiply(this.poolTokensOwned?.raw || BN_ZERO, this.reserve0.raw),
      this.totalSupply?.raw || BN_ONE,
    );
    return new TokenAmount(this.token0, fraction.quotient);
  }

  public get ownedAsset1(): TokenAmount {
    const fraction = new Fraction(
      JSBI.multiply(this.poolTokensOwned?.raw || BN_ZERO, this.reserve1.raw),
      this.totalSupply?.raw || BN_ONE,
    );
    return new TokenAmount(this.token0, fraction.quotient);
  }

  public calculateAssetFromExactValue(token: Token, exactValue: BigintIsh) {
    const fraction = new Fraction(
      JSBI.multiply(
        JSBI.BigInt(parseUnits(exactValue.toString() || BN_ZERO.toString(), token.decimals)),
        token.equals(this.token0) ? this.reserve0.raw : this.reserve1.raw,
      ),
      this.totalSupply?.raw || BN_ONE,
    );
    return new TokenAmount(this.token0, fraction.quotient);
  }

  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  public involvesToken(token: Token): boolean {
    const isEqualAddrAndChainId = token.equals(this.token0) || token.equals(this.token1);

    if (this.pairSource.type === PairSourceType.CROSSCHAIN_PORTFOLIO) {
      return (
        isEqualAddrAndChainId ||
        token.equalsBySymbol(this.token0) ||
        token.equalsBySymbol(this.token1)
      );
    }

    return isEqualAddrAndChainId;
  }

  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  public priceOf(token: Token): Price {
    invariant(this.involvesToken(token), 'TOKEN');
    return token.equals(this.token0) ? this.token0Price : this.token1Price;
  }

  public reserveOf(token: Token): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN');
    return token.equals(this.token0) ? this.reserve0 : this.reserve1;
  }

  /**
   * @param inputAmount amount of input token
   */
  public getOutputAmount(inputAmount: TokenAmount): [TokenAmount, Pair] {
    const inputToken = inputAmount.token;
    const outputToken = inputAmount.token.equals(this.token0) ? this.token1 : this.token0;

    invariant(this.involvesToken(inputAmount.token), 'TOKEN');
    if (JSBI.equal(this.reserve0.raw, BN_ZERO) || JSBI.equal(this.reserve1.raw, BN_ZERO)) {
      throw new InsufficientReservesError();
    }
    const inputReserve = this.reserveOf(inputToken);
    const outputReserve = this.reserveOf(outputToken);

    const isStableSwap = this.pairSource.portfolio?.isStableswap;
    const outputAmount = isStableSwap
      ? this.calcOutputAmountForStableSwap(outputToken, inputReserve, outputReserve, inputAmount)
      : this.calcOutputAmountForConstantProduct(
          outputToken,
          inputReserve,
          outputReserve,
          inputAmount,
        );

    if (JSBI.equal(outputAmount.raw, BN_ZERO)) {
      throw new InsufficientInputAmountError();
    }
    return [
      outputAmount,
      new Pair(
        this.tokenAddress,
        inputReserve.add(inputAmount),
        outputReserve.subtract(outputAmount),
      ),
    ];
  }
  private calcOutputAmountForConstantProduct(
    outputToken: Token,
    inputReserve: TokenAmount,
    outputReserve: TokenAmount,
    inputAmount: TokenAmount,
  ): TokenAmount {
    const inputAmountWithFee = JSBI.multiply(inputAmount.raw, FEES_NUMERATOR);
    const numerator = JSBI.multiply(inputAmountWithFee, outputReserve.raw);
    const denominator = JSBI.add(
      JSBI.multiply(inputReserve.raw, FEES_DENOMINATOR),
      inputAmountWithFee,
    );
    return new TokenAmount(outputToken, JSBI.divide(numerator, denominator));
  }
  private calcOutputAmountForStableSwap(
    outputToken: Token,
    inputReserve: TokenAmount,
    outputReserve: TokenAmount,
    inputAmount: TokenAmount,
  ): TokenAmount {
    return getSwapOutput({
      amplifier: STABLE_SWAP_AMPLIFIER,
      reserves: [inputReserve, outputReserve],
      inputToken: inputAmount.token,
      outputToken,
      amount: BigInt(inputAmount.raw.toString()), // in input token decimals
      fee: FEE,
    });
  }
  // ====

  /**
   * @param outputAmount amount of output token
   */
  public getInputAmount(outputAmount: TokenAmount): [TokenAmount, Pair] {
    const outputToken = outputAmount.token;
    const inputToken = outputAmount.token.equals(this.token0) ? this.token1 : this.token0;

    invariant(this.involvesToken(outputAmount.token), 'TOKEN');

    const outputReserve = this.reserveOf(outputToken);
    const inputReserve = this.reserveOf(inputToken);

    if (
      JSBI.equal(outputReserve.raw, BN_ZERO) ||
      JSBI.equal(inputReserve.raw, BN_ZERO) ||
      JSBI.greaterThanOrEqual(outputAmount.raw, outputReserve.raw)
    ) {
      throw new InsufficientReservesError();
    }

    const isStableSwap = this.pairSource.portfolio?.isStableswap;
    const inputAmount = isStableSwap
      ? this.calcInputAmountForStableSwap(inputToken, inputReserve, outputReserve, outputAmount)
      : this.calcInputAmountForConstantProduct(
          inputToken,
          inputReserve,
          outputReserve,
          outputAmount,
        );

    return [
      inputAmount,
      new Pair(
        this.tokenAddress,
        inputReserve.add(inputAmount),
        outputReserve.subtract(outputAmount),
      ),
    ];
  }
  private calcInputAmountForConstantProduct(
    inputToken: Token,
    inputReserve: TokenAmount,
    outputReserve: TokenAmount,
    outputAmount: TokenAmount,
  ): TokenAmount {
    const numerator = JSBI.multiply(
      JSBI.multiply(inputReserve.raw, outputAmount.raw),
      FEES_DENOMINATOR,
    );
    const denominator = JSBI.multiply(
      JSBI.subtract(outputReserve.raw, outputAmount.raw),
      FEES_NUMERATOR,
    );

    return new TokenAmount(inputToken, JSBI.add(JSBI.divide(numerator, denominator), BN_ONE));
  }
  private calcInputAmountForStableSwap(
    inputToken: Token,
    inputReserve: TokenAmount,
    outputReserve: TokenAmount,
    outputAmount: TokenAmount,
  ): TokenAmount {
    return getSwapInput({
      amplifier: STABLE_SWAP_AMPLIFIER,
      reserves: [inputReserve, outputReserve],
      inputToken: outputAmount.token,
      outputToken: inputToken,
      amount: BigInt(outputAmount.raw.toString()), // in output token decimals
      fee: FEE,
    });
  }
  // ====

  public getLiquidityMinted(
    totalSupply: TokenAmount,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount,
  ): TokenAmount {
    console.warn('[DEPRECATED] getLiquidityMinted');

    invariant(totalSupply.token.equals(this.liquidityToken), 'LIQUIDITY');
    // const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
    //   ? [tokenAmountA, tokenAmountB]
    //   : [tokenAmountB, tokenAmountA];
    const tokenAmounts = [tokenAmountA, tokenAmountB];

    invariant(
      tokenAmounts[0].token.equals(this.token0) && tokenAmounts[1].token.equals(this.token1),
      'TOKEN',
    );

    let liquidity: JSBI;
    if (JSBI.equal(totalSupply.raw, BN_ZERO)) {
      liquidity = JSBI.subtract(
        sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)),
        MINIMUM_LIQUIDITY,
      );
    } else {
      const amount0 = JSBI.divide(
        JSBI.multiply(tokenAmounts[0].raw, totalSupply.raw),
        this.reserve0.raw,
      );
      const amount1 = JSBI.divide(
        JSBI.multiply(tokenAmounts[1].raw, totalSupply.raw),
        this.reserve1.raw,
      );
      liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1;
    }
    if (!JSBI.greaterThan(liquidity, BN_ZERO)) {
      throw new InsufficientInputAmountError();
    }
    return new TokenAmount(this.liquidityToken, liquidity);
  }
}
