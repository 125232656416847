import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ITokenState } from '@/store/modules/tokens/models/token-state.interface';
import { getCrossChainTokens } from '@/helpers/cross-chain-api';
import { ICrossChainToken } from '@/store/modules/tokens/models/cross-chain-token.interface';
import { ICrossChainTokenItem } from '@/store/modules/tokens/models/cross-chain-token-item.interface';
import { ChainId } from '@/sdk/constants';
import { ITokenDescription, Token } from '@/sdk/entities/token';
import { compareTokenAddresses } from '@/sdk/utils';
import { DEFAULT_NETWORK_ID, NETWORK_USD_TOKEN_ADDRESS } from '@/helpers/networkParams.helper';
import { ENABLE_FAKE_CARDANO_NETWORK, isFakeCardanoChainId } from '@/helpers/fakeCardanoNetwork';
import { loadCardanoTokens } from './loadCardanoTokens';
import cardanoTokensConfig from '@/data/lists/cardano.tokenlist.json';

const tokens = ref<ITokenState | {}>({});

export const useTokens = defineStore('tokens', () => {
  function getTokensListByChainId(chainId: ChainId): Token[] {
    return tokens.value[chainId] || [];
  }

  function getTokenByAddressAndChainId(address: string, chainId: ChainId): Token {
    const token = tokens.value[chainId]?.find(t => compareTokenAddresses(t.address, address));

    if (!token) {
      throw new Error(`[TOKENS STORE] Token '${address}' was not found.`);
    }

    return token;
  }

  function getTokenBySymbolAndChainId(symbol: string, chainId: ChainId): Token {
    const token = tokens.value[chainId]?.find(t => t.symbol.toLowerCase() === symbol.toLowerCase());
    if (!token) {
      throw new Error(`[TOKENS STORE] Token '${symbol}' was not found for chain '${chainId}'.`);
    }
    return token;
  }

  function isPresentTokenIntoNetwork(symbol: string, chainId: ChainId): boolean {
    return !!tokens.value[chainId]?.find(t => t.symbol.toLowerCase() === symbol.toLowerCase());
  }

  function isPresentTokenIntoNetworkByAddress(address: string, chainId: ChainId): boolean {
    return !!tokens.value[chainId]?.find(t => t.address.toLowerCase() === address.toLowerCase());
  }

  /**
   * @param chainId get tokens for chainId
   * @param filter search token by symbol (filter is object which contains symbol field)
   * @param search search tokens by substring within token symbol
   *
   * @returns Token[]
   */
  const getTokensListByChainIdWithFilter = (
    chainId: ChainId,
    filter?: Partial<Token> | null,
    search?: string,
  ): Token[] => {
    const tokens: Token[] = getTokensListByChainId(chainId);
    if (!tokens?.length) return [];

    if (filter && filter.symbol)
      return tokens.filter(token => token.symbol?.toLowerCase() === filter.symbol?.toLowerCase());

    if (search) {
      return tokens.filter(token =>
        token.symbol?.toLowerCase().includes(search.trim().toLowerCase()),
      );
    }

    return tokens;
  };

  /**
   * @param filter search token by symbol (filter is object which contains symbol field)
   * @param search search tokens by substring within token symbol
   *
   * @returns single Token | map of Tokens
   */
  // TODO: getter from token module
  const getTokensWithFilter = (
    filter?: Partial<Token>,
    search?: string,
  ): Record<string, Token> | Token | null => {
    const tokens: Token[] = getTokensListByChainIdWithFilter(
      DEFAULT_NETWORK_ID! as unknown as ChainId,
      filter,
      search,
    );

    if (!tokens.length) return null;

    const tokensBySymbol = {};
    tokens.forEach(token => (tokensBySymbol[token.symbol!] = token));

    if (filter && filter.symbol) return tokensBySymbol[filter.symbol];

    return tokensBySymbol;
  };

  const filterByPresentLocally = (tokens: Token[] = []): Token[] => {
    return tokens.filter(token => token.description?.isPresentLocally ?? true);
  };

  const getFirstPresentLocallyToken = (notToken: Token) => {
    const tokens: Token[] = filterByPresentLocally(
      getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId),
    );

    return tokens.filter(token => token.address !== notToken.address)[0];
  };

  const getGasToken = () => {
    const tokens: Token[] = getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId);
    const gasPresentLocallyTokens = filterByPresentLocally(
      tokens.filter(token => token.isETHToken()),
    );
    return gasPresentLocallyTokens[0];
  };

  // NOTE: 'getBaseToken' getter from token module
  const getUSDToken = () => {
    const tokens: Token[] = getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId);
    return tokens.find(token => token.address === NETWORK_USD_TOKEN_ADDRESS)!;
  };

  // NOTE: 'getBaseTokenAddress' getter from token module
  const getUSDTokenAddress = () => {
    return NETWORK_USD_TOKEN_ADDRESS!;
  };

  // NOTE: 'getNativeToken' getter from token module
  const getBLUESToken = () => {
    const tokens: Token[] = getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId);
    return tokens.find(token => token.symbol === 'BLUES')!;
  };

  return {
    getBLUESToken,
    getUSDToken,
    getUSDTokenAddress,
    getGasToken,
    getFirstPresentLocallyToken,
    getTokensWithFilter,
    getTokensListByChainId,
    getTokensListByChainIdWithFilter,
    getTokenByAddressAndChainId,
    getTokenBySymbolAndChainId,
    filterByPresentLocally,
    isPresentTokenIntoNetwork,
    isPresentTokenIntoNetworkByAddress,
  };
});

export async function loadTokens(): Promise<ITokenState | {}> {
  await loadCrossChainTokens();

  if (ENABLE_FAKE_CARDANO_NETWORK) {
    loadAllCardanoTokens();
  }

  loggingTokensList(tokens.value);
  return tokens.value;
}

async function loadCrossChainTokens(): Promise<ITokenState | {}> {
  let crossChainTokens: ICrossChainToken[] = [];
  try {
    console.log('[LOAD:CROSS_CHAIN:TOKENS:LIST] chain : ', DEFAULT_NETWORK_ID);
    const crossChainTokensResponse = await getCrossChainTokens(
      DEFAULT_NETWORK_ID as unknown as ChainId,
    );
    crossChainTokens = crossChainTokensResponse.token;

    const doTokenList = ENABLE_FAKE_CARDANO_NETWORK
      ? prepareTokenListForFakeCardanoNetwork
      : prepareTokenList;
    const tokensList = doTokenList(crossChainTokens);

    loggingAddedCrossChainTokensToTokensList(tokensList);
    tokens.value = tokensList;
    return tokensList;
  } catch (e) {
    throw Error("Can't get tokens list from cross chain dex.");
  }
}

function loadAllCardanoTokens(): ITokenState | {} {
  try {
    const tokensList = tokens.value;
    cardanoTokensConfig.tokens.forEach(cardanoToken => {
      const {
        chainId: chainIdKey,
        address,
        decimals,
        name,
        symbol,
        logoURI: iconLink,
      } = cardanoToken;

      if (!tokensList[chainIdKey]) tokensList[chainIdKey] = [];

      const token = new Token(chainIdKey, address, decimals, symbol, name, '', iconLink);
      tokensList[chainIdKey].push(token);
    });

    loggingAddedCardanoTokensToTokensList(tokensList);
    tokens.value = tokensList;
    return tokensList;
  } catch (e) {
    throw Error("Can't get cardano tokens.");
  }
}

/**
 * Create a regular token or Wrapped GAS token for chain.
 *
 * @returns {Token} regular token or Wrapped GAS token for chain.
 */
function createToken(crToken: ICrossChainToken, chainTokenInfo: ICrossChainTokenItem): Token {
  const { name, iconLink, decimals, symbol: crSymbol } = crToken;
  const { chainId, address, symbol } = chainTokenInfo;

  const tokenDescription: ITokenDescription = buildTokenDescription(crToken, chainTokenInfo);

  return new Token(
    +chainId,
    address,
    decimals,
    chainId === (DEFAULT_NETWORK_ID as unknown as ChainId) ? symbol : crSymbol,
    name,
    '',
    iconLink,
    tokenDescription,
  );
}

/**
 * Return token description
 *
 * @returns {ITokenDescription} description contains token type, cross-chain symbol, gas token if wrapped gas token
 */
function buildTokenDescription(
  crToken: ICrossChainToken,
  chainTokenInfo: ICrossChainTokenItem,
): ITokenDescription {
  const { symbol: crossChainSymbol } = crToken;
  const { gasToken, isPresentLocally } = chainTokenInfo;

  // is gas token for `chainId` from `chainTokenInfo`
  const isGasTokenInTokenChain = !!gasToken;

  return {
    tokenType: isGasTokenInTokenChain ? 'WGAS' : 'ERC20',
    crossChainSymbol,
    isPresentLocally,
    gasToken: isGasTokenInTokenChain ? createGasToken(crToken, chainTokenInfo) : undefined,
  };
}

/**
 * Create GAS token for chain.
 *
 * @returns {Token} GAS token for chain.
 */
function createGasToken(crToken: ICrossChainToken, chainTokenInfo: ICrossChainTokenItem): Token {
  const { symbol: crossChainSymbol, iconLink, decimals } = crToken;
  const { chainId, address, gasToken: gasTokenSymbol, isPresentLocally } = chainTokenInfo;

  return new Token(
    +chainId,
    address,
    decimals,
    gasTokenSymbol,
    `${gasTokenSymbol} native token`,
    '',
    iconLink,
    {
      tokenType: 'GAS',
      crossChainSymbol,
      isPresentLocally,
    },
  );
}

function prepareTokenList(crossChainTokens: ICrossChainToken[]) {
  const tokensList: ITokenState | {} = {};

  crossChainTokens.forEach((crToken: ICrossChainToken) => {
    Object.entries(crToken.chainTokens).forEach(([chainIdKey, chainTokenInfo]) => {
      if (!tokensList[chainIdKey]) tokensList[chainIdKey] = [];

      // add wrapped gas token or erc20
      const token = createToken(crToken, chainTokenInfo);
      tokensList[chainIdKey].push(token);
      // add gas token
      if (token.isBaseToken() && token.unwrapWETH().isETHToken()) {
        tokensList[chainIdKey].push(token.unwrapWETH());
      }
    });
  });
  return tokensList;
}

function prepareTokenListForFakeCardanoNetwork(crossChainTokens: ICrossChainToken[]) {
  const tokensList: ITokenState | {} = {};
  const cardanoTokens = loadCardanoTokens();
  const cardanoTokensBySymbol = {};
  cardanoTokens.forEach(cardanoToken => {
    cardanoTokensBySymbol[cardanoToken.symbol] = cardanoToken;
  });

  crossChainTokens.forEach((crToken: ICrossChainToken) => {
    Object.entries(crToken.chainTokens).forEach(([chainIdKey, chainTokenInfo]) => {
      if (!tokensList[chainIdKey]) tokensList[chainIdKey] = [];

      if (isFakeCardanoChainId(chainIdKey)) {
        const cardanoToken = cardanoTokensBySymbol[crToken.symbol];
        if (!chainTokenInfo.isPresentLocally) {
          chainTokenInfo.isPresentLocally = !!cardanoToken;
        }
        if (chainTokenInfo.isPresentLocally) {
          chainTokenInfo.symbol = crToken.symbol;
          chainTokenInfo.gasToken = chainTokenInfo.gasToken
            ? crToken.symbol
            : chainTokenInfo.gasToken;
        }
      }

      // wrapped gas token or erc20
      let token = createToken(crToken, chainTokenInfo);

      if (!isFakeCardanoChainId(chainIdKey)) {
        // add wrapped gas token or erc20
        tokensList[chainIdKey].push(token);
      }

      // gas token
      if (token.isBaseToken() && token.unwrapWETH().isETHToken()) {
        token = token.unwrapWETH();
        tokensList[chainIdKey].push(token);
      } else if (isFakeCardanoChainId(chainIdKey)) {
        tokensList[chainIdKey].push(token);
      }
    });
  });
  return tokensList;
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].TOKENS;
}

function loggingTokensList(tokensList) {
  console.log('[TOKENS] Tokens list : ', tokensList);
}

function loggingAddedCrossChainTokensToTokensList(tokensList) {
  if (isLoggingDisabled()) return;

  console.log('[TOKENS] Added cross-chain tokens to tokens list : ', tokensList);
}

function loggingAddedCardanoTokensToTokensList(tokensList) {
  if (isLoggingDisabled()) return;

  console.log('[TOKENS] Added Cardano tokens to tokens list : ', tokensList);
}
