import { createPinia } from 'pinia';

(window as any).global = window;
import { createApp } from 'vue';
import { LockPlugin } from '@snapshot-labs/lock/plugins/vue3';
import options from '@/utils/auth';
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Panel from 'primevue/panel';
import OverlayPanel from 'primevue/overlaypanel';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Tag from 'primevue/tag';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Skeleton from 'primevue/skeleton';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import RadioButton from 'primevue/radiobutton';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/helpers/i18n';
import VueGtag from 'vue-gtag';
import Hotjar from 'vue-hotjar';
import { createHead } from '@vueuse/head';
import { isProductionURL } from '@/helpers/appParams.helper';
import GtmClass from '@/directives/GtmClass';
import VueSocialSharing from 'vue-social-sharing';
import apolloProvider from '@/utils/apollo-client';
import Chart from 'primevue/chart';

const pinia = createPinia();
const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(LockPlugin, options)
  .use(PrimeVue)
  .use(createHead())
  .use(pinia)
  .use(Hotjar, {
    id: '3101569',
    isProduction: isProductionURL(),
  });

if (isProductionURL()) {
  app.use(
    VueGtag,
    {
      config: { id: 'GTM-PLJXCN8' },
    },
    router,
  );
}

app.component('Button', Button);
app.component('Checkbox', Checkbox);
app.component('Dialog', Dialog);
app.component('OverlayPanel', OverlayPanel);
app.component('Panel', Panel);
app.component('Dropdown', Dropdown);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Divider', Divider);
app.component('Tag', Tag);
app.component('Skeleton', Skeleton);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('ConfirmDialog', ConfirmDialog);
app.component('RadioButton', RadioButton);
app.component('Chart', Chart);

app.directive('tooltip', Tooltip);
app.directive('gtm', GtmClass);
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueSocialSharing);
app.use(apolloProvider);

app.mount('#app');

window['BLUESHIFT_DEBUG'] = {
  BALANCES: false,
  TOKENS: false,
  ALLOWANCE_TOKEN: false,
  PORTFOLIOS: false,
  PORTFOLIO_INFO: false,
  FARMING: false,
  AUTO_POOLS: false,
  MANUAL_POOLS: false,
  EASY_MODE_BALANCES: false,
  EASY_MODE_ADD_LIQUIDITY: false,
  EASY_MODE_WITHDRAW: false,
  SINGLE_SIDE_DEPOSIT: false,
  SINGLE_SIDE_WITHDRAW: false,
  WSC: false,
  LOOKING_FOR_TRADE: false,
};

export default app;
