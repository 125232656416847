import { Trade } from '@/sdk/entities/trade';
import { ChainId } from '@/sdk/constants';
import { Token } from '@/sdk/entities/token';
import { PairSourceType } from '@/sdk/entities/pairSource';

export function getDestinationChainFromTokens(fromToken: Token, toToken: Token): ChainId | 0 {
  return fromToken.chainId === toToken.chainId ? 0 : toToken.chainId;
}

export function getDestinationChainIdForRequest(trade: Trade): ChainId | 0 {
  return trade.getDestinationChainId();
}

export function isCrossChainSwap(trade: Trade): boolean {
  return getDestinationChainIdForRequest(trade) !== 0;
}

export function isValidTrade(trade: Trade, isCrossChainTrade: boolean) {
  if (isCrossChainTrade) {
    return filterCrossChainTradesByOnlyLast(trade);
  }

  if (!isCrossChainTrade && checkTradeForCrossChainPairs(trade)) {
    return filterDirectCrossChainTrade(trade);
  }

  return true;
  // return !isCrossChainSwap(trade) || filterCrossChainTradesByOnlyLast(trade);
}

/**
 * Check trade for crosschain pairs exists
 * @param trade
 *
 * @return number of crosschain pairs in trade
 */
function checkTradeForCrossChainPairs(trade: Trade): number {
  return trade.route.pairs.filter(
    pair => pair.pairSource.type === PairSourceType.CROSSCHAIN_PORTFOLIO,
  ).length;
}

function filterCrossChainTradesByOnlyLast(trade: Trade) {
  return getDestinationChainIdForRequest(trade) !== 0 && checkTradeForCrossChainPairs(trade) === 1;
}

function filterDirectCrossChainTrade(trade: Trade) {
  return trade.route.pairs.length === 1 && checkTradeForCrossChainPairs(trade) === 1;
}

export function chooseBestTrade(
  trades: Trade[] = [],
  localSwapPriority: boolean,
  fromToken: Token,
  toToken: Token,
): Trade[] {
  const bestTrades: Trade[] = [];
  console.log('chooseBestTrade', trades, localSwapPriority, fromToken.chainId, toToken.chainId);

  const isCrossChainTrade = fromToken.chainId !== toToken.chainId;
  trades = trades.filter(trade => isValidTrade(trade, isCrossChainTrade));

  console.log('filtered trades', trades);

  if (fromToken.chainId !== toToken.chainId) {
    // only crossChain
    console.log('fromToken.chainId !== toToken.chainId');
    trades.forEach(trade => {
      if (isCrossChainSwap(trade)) {
        console.log('has crosschain reserves');
        bestTrades.push(trade);
      }
    });
  } else if (!localSwapPriority) {
    // it can be local or crosschain
    console.log('localSwapPriority false');
    trades.forEach(trade => {
      if (!isCrossChainSwap(trade)) {
        //if local add to trades
        bestTrades.push(trade);
      } else {
        console.log('has crosschain reserves');
        // if crosschain and has reserves
        bestTrades.push(trade);
      }
    });
  } else {
    console.log('localSwapPriority true');
    console.log(trades);
    trades.forEach(trade => {
      if (!isCrossChainSwap(trade)) {
        bestTrades.push(trade);
      }
    });
    if (bestTrades.length > 0) {
      console.log('founded local swaps');
      return bestTrades;
    }
    trades.forEach(trade => {
      if (isCrossChainSwap(trade)) {
        console.log('has crosschain reserves');
        bestTrades.push(trade);
      }
    });
    console.log(bestTrades);
  }
  return bestTrades;
}
