import erc20 from '@/data/abi/erc20.json';
import multicall from '@/utils/multicall';
import { BigNumber } from '@ethersproject/bignumber';
import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';
import { PortfolioPairs, VirtualReserves } from '@/sdk/entities/portfolioPairs';
import { getPortfolioContract } from '@/helpers/contract.helper';
import { TokenInfo } from '@/sdk/entities/portfolio';

export type TotalValueResponse = {
  totalValue: string[];
  totalValueUSD: string[];
};

export async function fetchVolumes(portfolioAddresses: string[]): Promise<string[]> {
  const volumeRequest = await fetch(`${NETWORK_BACKEND_URL + 'portfolio/volume'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      portfolio: portfolioAddresses,
      period: 30,
    }),
  });
  const volumesJson: { volume: string[] } = await volumeRequest.json();
  return volumesJson.volume;
}

export async function fetchFees(portfolioAddresses: string[]): Promise<string[]> {
  const feeRequest = await fetch(`${NETWORK_BACKEND_URL + 'portfolio/fee'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      portfolio: portfolioAddresses,
      period: 30,
    }),
  });
  const feesJson: { fee: string[] } = await feeRequest.json();
  return feesJson.fee;
}

export async function fetchTotalSupply(lpTokenAddresses: string[]): Promise<BigNumber[]> {
  const callsTotalSupply = lpTokenAddresses.map(address => ({
    address,
    name: 'totalSupply',
  }));

  return multicall(erc20, callsTotalSupply);
}

export async function fetchPortfolioTokensOwned(
  lpTokenAddresses: string[],
  userAddress: string,
): Promise<Promise<BigNumber[][]>> {
  const callsTotalSupply = lpTokenAddresses.map(address => ({
    address,
    name: 'balanceOf',
    params: [userAddress],
  }));

  return multicall(erc20, callsTotalSupply);
}
export async function fetchPortfolioPairsVirtualReserves(portfolioPairs: PortfolioPairs): Promise<{
  keys: string[];
  values: PromiseSettledResult<VirtualReserves>[];
}> {
  const portfolioContract = getPortfolioContract(portfolioPairs.portfolioContractAddress);
  const pairs = portfolioPairs.getTokensPairs();
  loggingPortfolioPairs(portfolioPairs.portfolioContractAddress, pairs);

  const keys: string[] = [];
  const callsVirtualReserves: Promise<VirtualReserves>[] = [];

  pairs.forEach(pair => {
    const args = [pair[0].token.address, pair[1].token.address];

    keys.push(`${args[0]}-${args[1]}`);
    callsVirtualReserves.push(portfolioContract.getVirtualReserves(args[0], args[1]));
  });

  return { keys, values: await Promise.allSettled(callsVirtualReserves) };
}

// DEBUG

function loggingPortfolioPairs(id: string, pairs: TokenInfo[][]) {
  console.groupCollapsed(`[${id}] PAIRS : `);
  pairs.forEach(pair => {
    console.log(`${pair[0].token.symbol} - ${pair[1].token.symbol} : `, pair);
  });
  console.groupEnd();
}
