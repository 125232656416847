import {
  BigintIsh,
  BN_ZERO,
  ChainId,
  ethersToJSBI,
  MIN_ALLOWED_WEIGHT,
  NO_CONTRACT_ADDRESS,
} from '../constants';
import { fromWei } from '../utils';
import { TokenAmount } from './fractions/tokenAmount';
import { Pair } from './pair';
import { PairSourceType } from './pairSource';
import { Portfolio, TokenInfo } from './portfolio';
import { PortfolioSource } from './PortfolioSource';

export type VirtualReserves = {
  reserveFrom: BigintIsh;
  reserveTo: BigintIsh;
  key: string;
};

export type VirtualReservesByChainId = VirtualReserves & {
  chainId: ChainId;
  pair: Pair;
};

export class PortfolioPairs {
  private portfolio: Portfolio;

  pairs: { [k: string]: Pair } = {};
  virtualReserves: VirtualReserves[] = [];

  constructor(portfolio: Portfolio) {
    this.portfolio = portfolio;
  }

  get portfolioContractAddress() {
    return this.portfolio.contractAddress;
  }

  get tokensByAddr() {
    return this.portfolio.tokensByAddr;
  }

  get tokens() {
    return this.portfolio.tokens;
  }

  get crossChainTokens() {
    return this.portfolio.crossChainTokens;
  }

  // getPairsArray() {
  getTokensPairs() {
    const localTokens: TokenInfo[] = this.tokens.map(t => t);
    // const crossChainTokens: TokenInfo[] = this.crossChainTokens.map(t => t);
    // let tokensArr: TokenInfo[] = [...localTokens, ...crossChainTokens];
    let tokensArr: TokenInfo[] = [...localTokens];

    // console.log(`[${this.portfolioContractAddress}] [TOKEN_PAIRS] before`, tokensArr);

    tokensArr = tokensArr.filter(token =>
      token
        .getPortfolioSharePercent(this.portfolio.portfolioTotalValueBase)
        .div(token.targetWeight.multipliedBy(100))
        .gte(MIN_ALLOWED_WEIGHT),
    );

    // console.log(
    //   `[${this.portfolioContractAddress}] [TOKEN_PAIRS] after filter [MIN_ALLOWED_WEIGHT]`,
    //   tokensArr,
    // );

    const comb: TokenInfo[][] = [];
    tokensArr.flatMap(tokenA =>
      tokensArr.forEach(tokenB => {
        if (tokenA.token.equals(tokenB.token)) {
          return;
        }
        comb.push([tokenA, tokenB]);
      }),
    );

    return comb.map(combination => [combination[0], combination[1]]);
  }

  createPairs() {
    this.getTokensPairs().forEach((pair, i) => {
      const pairId = `${pair[0].token.address}--${pair[1].token.address}`;
      const reserves = this.virtualReserves[i];
      this.pairs[pairId] = new Pair(
        NO_CONTRACT_ADDRESS,
        new TokenAmount(
          this.tokensByAddr[pair[0].token.address].unwrapWETH(),
          reserves.reserveFrom,
        ),
        new TokenAmount(this.tokensByAddr[pair[1].token.address].unwrapWETH(), reserves.reserveTo),
        BN_ZERO,
        BN_ZERO,
        {
          type:
            this.portfolio.type === PortfolioSource.PORTFOLIO_LOCALE
              ? PairSourceType.PORTFOLIO
              : PairSourceType.CROSSCHAIN_PORTFOLIO,
          address: this.portfolio.contractAddress,
          name: this.portfolio.name,
          id: this.portfolio.portfolioId,
          portfolio: this.portfolio,
        },
      );
    });

    console.log(`[${this.portfolioContractAddress}] [PORTFOLIO:PAIRS] : `, this.pairs);
  }

  createVirtualReserves(keys: string[], reserves) {
    this.virtualReserves = reserves.map(({ value }, idx) => ({
      reserveFrom: ethersToJSBI(value ? value['reserveFrom'] : BN_ZERO),
      reserveTo: ethersToJSBI(value ? value['reserveTo'] : BN_ZERO),
      key: keys[idx],
    }));

    const pairs = this.getTokensPairs();

    console.log(
      `[${this.portfolioContractAddress}] [VIRTUAL_RESERVES] : `,
      this.virtualReserves.map((v, i) => {
        return {
          key: v.key,
          [`reserveFrom [${pairs[i][0].token.symbol}]`]: fromWei(
            v.reserveFrom.toString(),
            pairs[i][0].token.decimals,
          ).toString(),
          [`reserveTo [${pairs[i][1].token.symbol}]`]: fromWei(
            v.reserveTo.toString(),
            pairs[i][1].token.decimals,
          ).toString(),
        };
      }),
    );
  }
}
