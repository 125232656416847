import { Fraction } from '@/sdk/entities/fractions/fraction';
import { Currency } from '@/sdk/entities/currency';
import { Route } from '@/sdk/entities/route';
import { BigintIsh, BN_TEN, Rounding } from '@/sdk/constants';
import JSBI from 'jsbi';
import invariant from 'tiny-invariant';
import { currencyEquals, Token } from '@/sdk/entities/token';
import { CurrencyAmount } from '@/sdk/entities/fractions/currencyAmount';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';

export class Price extends Fraction {
  public readonly baseCurrency: Currency; // input i.e. denominator
  public readonly quoteCurrency: Currency; // output i.e. numerator
  public readonly scalar: Fraction; // used to adjust the raw fraction w/r/t the decimals of the {base,quote}Token

  public static fromRoute(route: Route): Price {
    const prices: Price[] = [];
    for (const [i, pair] of route.pairs.entries()) {
      const isStableSwap = pair.pairSource.portfolio?.isStableswap;

      prices.push(
        route.path[i].equals(pair.token0)
          ? new Price(
              pair.reserve0.currency,
              pair.reserve1.currency,
              isStableSwap ? JSBI.BigInt(1) : pair.reserve0.raw,
              isStableSwap ? JSBI.BigInt(1) : pair.reserve1.raw,
            )
          : new Price(
              pair.reserve1.currency,
              pair.reserve0.currency,
              isStableSwap ? JSBI.BigInt(1) : pair.reserve1.raw,
              isStableSwap ? JSBI.BigInt(1) : pair.reserve0.raw,
            ),
      );
    }
    return prices
      .slice(1)
      .reduce((accumulator, currentValue) => accumulator.multiply(currentValue), prices[0]);
  }

  // denominator and numerator _must_ be raw, i.e. in the native representation
  public constructor(
    baseCurrency: Currency,
    quoteCurrency: Currency,
    denominator: BigintIsh,
    numerator: BigintIsh,
  ) {
    super(numerator, denominator);

    this.baseCurrency = baseCurrency;
    this.quoteCurrency = quoteCurrency;
    this.scalar = new Fraction(
      JSBI.exponentiate(BN_TEN, JSBI.BigInt(baseCurrency.decimals)),
      JSBI.exponentiate(BN_TEN, JSBI.BigInt(quoteCurrency.decimals)),
    );
  }

  public get raw(): Fraction {
    return new Fraction(this.numerator, this.denominator);
  }

  public get adjusted(): Fraction {
    return super.multiply(this.scalar);
  }

  public invert(): Price {
    return new Price(this.quoteCurrency, this.baseCurrency, this.numerator, this.denominator);
  }

  public multiply(other: Price): Price {
    invariant(currencyEquals(this.quoteCurrency, other.baseCurrency), 'TOKEN');
    const fraction = super.multiply(other);
    return new Price(
      this.baseCurrency,
      other.quoteCurrency,
      fraction.denominator,
      fraction.numerator,
    );
  }

  // performs floor division on overflow
  public quote(currencyAmount: CurrencyAmount): CurrencyAmount {
    invariant(currencyEquals(currencyAmount.currency, this.baseCurrency), 'TOKEN');
    if (this.quoteCurrency instanceof Token) {
      return new TokenAmount(this.quoteCurrency, super.multiply(currencyAmount.raw).quotient);
    }
    return CurrencyAmount.ether(super.multiply(currencyAmount.raw).quotient);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public toSignificant(significantDigits = 6, format?: object, rounding?: Rounding): string {
    return this.adjusted.toSignificant(significantDigits, format, rounding);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public toExact(significantDigits = 6, format?: object, rounding?: Rounding): string {
    return this.adjusted.toSignificant(significantDigits, format, rounding);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public toFixed(decimalPlaces = 4, format?: object, rounding?: Rounding): string {
    return this.adjusted.toFixed(decimalPlaces, format, rounding);
  }
}
