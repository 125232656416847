import { Portfolio } from '@/sdk/entities/portfolio';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { fetchPortfolioPairsVirtualReserves } from './portfolio-api';

export async function createPairsWithVirtualReserves(portfolio: Portfolio) {
  const ID = `${portfolio.name} | ${portfolio.contractAddress}`;

  console.log(`[${ID}] GET_VIRTUAL_RESERVES Portfolio : `, portfolio);
  const portfolioPairs = portfolio.portfolioPairs;

  if (portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN) return;

  try {
    const { keys, values: rawVirtualReserves } = await fetchPortfolioPairsVirtualReserves(
      portfolioPairs,
    );

    portfolioPairs.createVirtualReserves(keys, rawVirtualReserves);
    portfolioPairs.createPairs();
  } catch (e) {
    console.groupCollapsed(`[${ID}] [GET_VIRTUAL_RESERVES] Error`);
    console.log(`[${ID}] portfolio : `, portfolio);
    console.log(`[${ID}] portfolioPairs : `, portfolioPairs);
    console.groupEnd();

    throw e;
  }
}
