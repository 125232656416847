import { LP_TOKEN_DECIMALS } from '@/sdk/entities/constants/LP_TOKEN_DECIMALS';
import { applySlippageInPercents, fromWei, toFixedWei, toWei } from '@/sdk/utils';
import { PortfolioDto } from '@/store/modules/portfolios/models/portfolio-dto.interface';
import { PortfolioTokenDto } from '@/store/modules/portfolios/models/portfolio-token-dto.interface';
import BigNumber from 'bignumber.js';
import { BIG_TEN, BIG_ZERO, ethersToBigNumber, max, min, toBigNumber } from '@/utils/bigNumber';
import { BigintIsh, BN_ZERO, ChainId, PRICE_DECIMALS, WEIGHT_MULTIPLIER } from '@/sdk/constants';
import { BOT_USD_DECIMALS } from '@/helpers/decimals-env';
import { Token } from '@/sdk/entities/token';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Pair } from '@/sdk/entities/pair';
import JSBI from 'jsbi';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { ethers } from 'ethers';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { PortfolioPairs } from './portfolioPairs';

const PORTFOLIO_LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingPortfoliosDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingPortfoliosDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingPortfoliosDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export class WithdrawTokens {
  token: Token;
  tokenAddress: string;
  checked: boolean;
  limit: TokenAmount;
  limitByAmount: TokenAmount;
  price: BigNumber;
  externalPrice: BigNumber;
  /**
   * [R] withdraw amount in relative units
   */
  private value: BigNumber | null;
  /**
   * [R] withdraw amount in relative units of lp token
   */
  private lpValue: BigNumber;

  constructor(token: TokenInfo) {
    this.token = token.token;
    this.tokenAddress = token.tokenAddress;
    this.checked = false;
    this.limit = token.amount;
    this.limitByAmount = token.amount;
    this.value = BIG_ZERO;
    this.lpValue = BIG_ZERO;
    this.price = token.price;
    this.externalPrice = token.withdrawEMAPrice;
  }

  /**
   * [W] withdraw amount in wei units
   */
  public get weiValue(): BigNumber | null {
    if (!this.value) {
      return null;
    }
    return toWei(this.value, this.token.decimals);
  }

  /**
   * [W] withdraw amount in wei units of lp token
   */
  public get lpWeiValue(): BigNumber {
    return toWei(this.lpValue, LP_TOKEN_DECIMALS);
  }

  public updateValue(value: number | string | BigNumber | null) {
    if (value === null) {
      this.value = null;
      return;
    }
    this.value = new BigNumber(value!);
  }

  public updateLpValue(lpValue: number | string | BigNumber) {
    this.lpValue = new BigNumber(lpValue);
  }

  /**
   * [R] withdraw price of 1 token in base token
   */
  // TODO: [W/W] withdraw price of 1 wei of this token in base token weis.
  public getWithdrawPrice(): BigNumber {
    return max(this.price, this.externalPrice);
  }

  public getMinAmountOutWei(slippageTolerance: number): string {
    if (!this.weiValue) {
      throw new Error('Withdraw token value is null');
    }
    return applySlippageInPercents(this.weiValue, slippageTolerance).toFixed(0);
  }

  public getLimit(): TokenAmount {
    return JSBI.lessThanOrEqual(this.limitByAmount.raw, this.limit.raw)
      ? this.limitByAmount
      : this.limit;
  }
}

export interface FilledTokensObject {
  value: string;
  checked: boolean;
  hasError: boolean;
  hasOnlyBalanceError: boolean;
}

export class TokenInfo {
  public isPresentLocally: boolean;
  public token: Token;
  public tokenAddress: string;
  public amount: TokenAmount;
  public targetWeight: BigNumber;
  public availableOn: string[];
  public readonly baseToken: Token;

  /**
   * [R] price of one token in base currency
   */
  // TODO: [W/W] price of one token wei in base currency weis
  public price: BigNumber;

  /**
   * [R] deposit EMA price of one token
   */
  // TODO: [W/W] deposit EMA price of one token wei
  public depositEMAPrice: BigNumber;

  /**
   * [R] withdraw EMA price of one token
   */
  // TODO: [W/W] withdraw EMA price of one token wei
  public withdrawEMAPrice: BigNumber;

  public depositLimit: BigNumber;
  public withdrawLimit: BigNumber;

  constructor(tokenInfo: PortfolioTokenDto, baseToken: Token) {
    this.tokenAddress = tokenInfo.tokenAddress;
    this.isPresentLocally = tokenInfo.isPresentLocally === true;
    this.availableOn = tokenInfo.availableOn ?? [];
    this.baseToken = baseToken;

    const { getTokenByAddressAndChainId } = useTokens();
    this.token = getTokenByAddressAndChainId(this.tokenAddress, this.baseToken.chainId);

    this.amount = new TokenAmount(
      this.token,
      ethersToBigNumber(tokenInfo.amount).integerValue().toString(),
    );
    this.targetWeight = ethersToBigNumber(tokenInfo.targetWeight).dividedBy(WEIGHT_MULTIPLIER);

    const priceShift = this.token.decimals - this.baseToken.decimals;
    this.price = fromWei(ethersToBigNumber(tokenInfo.price), PRICE_DECIMALS).shiftedBy(priceShift);
    this.depositEMAPrice = fromWei(
      ethersToBigNumber(tokenInfo.depositEMAPrice),
      PRICE_DECIMALS,
    ).shiftedBy(priceShift);
    this.withdrawEMAPrice = fromWei(
      ethersToBigNumber(tokenInfo.withdrawEMAPrice),
      PRICE_DECIMALS,
    ).shiftedBy(priceShift);
    this.depositLimit = ethersToBigNumber(tokenInfo.depositLimit);
    this.withdrawLimit = ethersToBigNumber(tokenInfo.withdrawLimit);

    loggingInitTokenInfo(
      this.token,
      this.baseToken,
      this.targetWeight,
      tokenInfo,
      this.amount,
      this.price,
      this.depositEMAPrice,
      this.withdrawEMAPrice,
      this.depositLimit,
      this.withdrawLimit,
    );
  }

  /**
   * [W] token reserve equivalent in base currency wei units
   */
  public get baseTokenAmountEquivalent(): BigNumber {
    // BASE WEI / TOKEN WEI
    const priceInWei = this.price.shiftedBy(this.baseToken.decimals - this.token.decimals);
    // (BASE WEI / TOKEN WEI) * TOKEN WEI => BASE WEI
    return toFixedWei(priceInWei.multipliedBy(this.amount.raw.toString()));
  }

  public getPortfolioSharePercent(portfolioTotalValueInBase: TokenAmount): BigNumber {
    if (portfolioTotalValueInBase.equalTo('0')) {
      return new BigNumber(0);
    }

    // BASE WEI / TOKEN WEI
    const priceInWei = this.price.shiftedBy(this.baseToken.decimals - this.token.decimals);
    // (BASE WEI / TOKEN WEI) * TOKEN WEI => BASE WEI
    const amountInBaseWei = priceInWei.multipliedBy(this.amount.raw.toString());
    const portfolioTotalValueInBaseWei = portfolioTotalValueInBase.raw.toString();

    return amountInBaseWei.dividedBy(portfolioTotalValueInBaseWei).multipliedBy(100);
  }

  /**
   * [R] Returns deposit token price
   */
  // TODO: [W/W] Returns deposit token wei price.
  public getDepositPrice(): BigNumber {
    return min(this.price, this.depositEMAPrice);
  }

  /**
   * [R] Returns withdraw token price
   */
  // TODO: [W/W] Returns withdraw token wei price.
  public getWithdrawPrice(): BigNumber {
    return max(this.price, this.withdrawEMAPrice);
  }

  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   */
  public getPriceRelative(): BigNumber {
    return this.price;
  }

  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   */
  public getDepositPriceRelative(): BigNumber {
    return this.getDepositPrice();
  }

  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   */
  public getWithdrawPriceRelative(): BigNumber {
    return this.getWithdrawPrice();
  }
}

export class Portfolio {
  type: PortfolioSource;
  chainId: ChainId;
  portfolioId: number;
  name: string;
  contractAddress: string;
  baseTokenAddress: string;
  isBaseTokenPresentLocally?: boolean;
  baseToken: Token;
  lpTokenAddress: string;
  lpToken: Token;
  isStableswap: boolean;

  /**
   * [R] price of 1 LP per base token
   */
  // TODO: [W / W] price of 1 LP wei per base token wei
  lpTokenPriceBase: BigNumber;

  /**
   * [W] In base token wei units
   */
  totalValue: BigintIsh;
  tokenCount: BigNumber;
  tokens: TokenInfo[] = [];
  crossChainTokens: TokenInfo[] = [];
  tokensByAddr: { [k: string]: Token } = {};
  tokensInfoByAddr: { [k: string]: TokenInfo } = {};
  pairs: { [k: string]: Pair } = {};
  portfolioPairs: PortfolioPairs;

  portfolioTotalValueBase: TokenAmount;

  volume30: TokenAmount;
  fee30: TokenAmount;
  priceUSDToken: Token;

  /**
   * [USD / R] base token relative unit price in USD
   */
  priceInUSD: BigNumber;

  /**
   * [W] In LP (18 decimals) wei units
   */
  totalSupply: BigNumber;

  withdrawTokens: { [k: string]: WithdrawTokens } = {};
  balanceOfWallet: BigNumber;
  balanceRestOfWallet: BigNumber;

  constructor(portfolioInfo: PortfolioDto, portfolioType: PortfolioSource, currentChain: ChainId) {
    this.type = portfolioType;
    this.chainId = currentChain;
    this.name = portfolioInfo.name;
    this.portfolioId = portfolioInfo?.portfolioId;
    this.contractAddress = portfolioInfo.contractAddress;
    this.baseTokenAddress = portfolioInfo.baseTokenAddress;
    this.isBaseTokenPresentLocally = portfolioInfo.isBaseTokenPresentLocally;
    this.isStableswap = portfolioInfo.isStableswap;

    const { getTokenByAddressAndChainId, isPresentTokenIntoNetworkByAddress } = useTokens();
    this.baseToken = getTokenByAddressAndChainId(this.baseTokenAddress, this.chainId);

    this.lpTokenAddress = portfolioInfo.lpTokenAddress;
    // LP token
    const LPToken = {
      chainId: this.chainId,
      address: this.lpTokenAddress,
      symbol: 'LP',
      decimals: LP_TOKEN_DECIMALS,
    };

    if (
      ENABLE_FAKE_CARDANO_NETWORK &&
      isPresentTokenIntoNetworkByAddress(LPToken.address, LPToken.chainId)
    ) {
      const token = getTokenByAddressAndChainId(LPToken.address, LPToken.chainId);
      LPToken.symbol = token.symbol || LPToken.symbol;
      LPToken.decimals = token.decimals;
    }
    this.lpToken = new Token(LPToken.chainId, LPToken.address, LPToken.decimals, LPToken.symbol);

    // NOTE: Changed `LP_TOKEN_DECIMALS` -> this.lpToken.decimals
    this.lpTokenPriceBase = fromWei(
      ethersToBigNumber(portfolioInfo.lpTokenPrice),
      PRICE_DECIMALS,
    ).shiftedBy(this.lpToken.decimals - this.baseToken.decimals);

    this.totalValue = new TokenAmount(
      this.baseToken,
      ethersToBigNumber(portfolioInfo.totalValue).integerValue().toString(),
    ).raw;
    this.portfolioTotalValueBase = new TokenAmount(this.baseToken, this.totalValue);
    this.tokenCount = ethersToBigNumber(portfolioInfo.tokenCount);

    PORTFOLIO_LOGGER.groupCollapsed(
      `[PORTFOLIO:INIT] ${this.name} | ${this.contractAddress} | ${this.type} | ${this.portfolioId}`,
    );
    loggingInitPortfolio(
      this.baseToken,
      portfolioInfo,
      this.lpTokenPriceBase,
      this.totalValue,
      this.portfolioTotalValueBase,
    );

    portfolioInfo.tokens.forEach(token => {
      if (token.isPresentLocally === false) return;
      this.tokens.push(new TokenInfo(token, this.baseToken));
    });
    portfolioInfo.tokens.forEach(token => {
      if (this.type === PortfolioSource.PORTFOLIO_LOCALE) return;
      if (token.isPresentLocally === true) return;
      this.crossChainTokens.push(new TokenInfo(token, this.baseToken));
    });

    this.tokens.forEach((token: TokenInfo) => {
      this.tokensByAddr[token.tokenAddress] = token.token;
      this.tokensInfoByAddr[token.tokenAddress] = token;
      this.withdrawTokens[token.tokenAddress] = new WithdrawTokens(token);
    });
    this.crossChainTokens.forEach((token: TokenInfo) => {
      this.tokensByAddr[token.tokenAddress] = token.token;
      this.tokensInfoByAddr[token.tokenAddress] = token;
    });
    this.volume30 = new TokenAmount(this.baseToken, BN_ZERO);
    this.fee30 = new TokenAmount(this.baseToken, BN_ZERO);
    this.priceInUSD = BIG_ZERO;
    this.totalSupply = BIG_ZERO;
    this.balanceOfWallet = BIG_ZERO;
    this.balanceRestOfWallet = BIG_ZERO;
    this.priceUSDToken = new Token(
      currentChain,
      ethers.constants.AddressZero,
      BOT_USD_DECIMALS,
      'USD',
    );
    this.portfolioPairs = new PortfolioPairs(this);

    PORTFOLIO_LOGGER.log('portfolio constructor', this);
    PORTFOLIO_LOGGER.groupEnd();
  }

  get baseTokenInfo(): TokenInfo {
    return this.getTokenInfo(this.baseToken);
  }

  /**
   * [W] Total value in base token wei units.
   * totalValue + protocolFee === SUM(tokenPrice_i * tokenReserve_i)
   */
  get totalValueWithProtocolFee(): BigNumber {
    return Object.values(this.tokensInfoByAddr).reduce(
      (sum, token) => sum.plus(token.baseTokenAmountEquivalent),
      BIG_ZERO,
    );
  }

  public get printIPR(): number {
    const aprLeft = new BigNumber(this.fee30.toExact()).div(BIG_TEN.pow(6)).multipliedBy(12);
    const aprRight = new BigNumber(this.getValueInUSD(this.portfolioTotalValueBase.toExact()));
    return aprLeft.div(aprRight).multipliedBy(100).toNumber();
  }

  /**
   * set:
   *  - lpTokenPriceBase
   *  - totalValue
   *  - portfolioTotalValueBase
   *  - tokenCount
   */
  updatePortfolioInfo(portfolioInfo: PortfolioDto) {
    PORTFOLIO_LOGGER.groupCollapsed(`updatePortfolioInfo [${portfolioInfo.name}]`);

    loggingUpdatePortfolioInfo(
      'BEFORE',
      this.lpTokenPriceBase,
      this.totalValue,
      this.portfolioTotalValueBase,
      this.tokenCount,
      this.tokens,
      this.crossChainTokens,
    );

    // NOTE: Changed `LP_TOKEN_DECIMALS` -> this.lpToken.decimals
    this.lpTokenPriceBase = fromWei(
      ethersToBigNumber(portfolioInfo.lpTokenPrice),
      PRICE_DECIMALS,
    ).shiftedBy(this.lpToken.decimals - this.baseToken.decimals);

    this.totalValue = new TokenAmount(
      this.baseToken,
      ethersToBigNumber(portfolioInfo.totalValue).integerValue().toString(),
    ).raw;
    this.portfolioTotalValueBase = new TokenAmount(this.baseToken, this.totalValue);
    this.tokenCount = ethersToBigNumber(portfolioInfo.tokenCount);

    portfolioInfo.tokens.forEach(token => {
      const findIndex = this.tokens.findIndex(
        findToken => token.tokenAddress === findToken.tokenAddress,
      );
      if (findIndex > -1) {
        this.tokens[findIndex] = new TokenInfo(token, this.baseToken);
        return;
      }
      const findIndexCrossChain = this.crossChainTokens.findIndex(
        findToken => token.tokenAddress === findToken.tokenAddress,
      );
      if (findIndexCrossChain > -1) {
        this.crossChainTokens[findIndexCrossChain] = new TokenInfo(token, this.baseToken);
        return;
      }
    });

    this.tokens.forEach((token: TokenInfo) => {
      this.tokensByAddr[token.tokenAddress] = token.token;
      this.tokensInfoByAddr[token.tokenAddress] = token;
      this.withdrawTokens[token.tokenAddress] = new WithdrawTokens(token);
    });
    this.crossChainTokens.forEach((token: TokenInfo) => {
      this.tokensByAddr[token.tokenAddress] = token.token;
      this.tokensInfoByAddr[token.tokenAddress] = token;
    });

    loggingUpdatePortfolioInfo(
      'AFTER',
      this.lpTokenPriceBase,
      this.totalValue,
      this.portfolioTotalValueBase,
      this.tokenCount,
      this.tokens,
      this.crossChainTokens,
    );

    PORTFOLIO_LOGGER.groupEnd();
  }

  getTokenInfo(token: string | Token): TokenInfo {
    const address = token instanceof Token ? token.address : token;

    const tokenInfo = this.tokens.find(item => item.tokenAddress === address);
    if (!tokenInfo) {
      throw new Error(`Token ${token} not found in the portfolio.`);
    }

    return tokenInfo;
  }

  /**
   * [ USD / R ] returns price of one relative token unit in usd
   * @param tokenInfo
   */
  getTokenPriceInUSD(tokenInfo: TokenInfo, currentAction = 'addLiquidity'): BigNumber {
    let relativePrice: BigNumber;
    if (currentAction === 'addLiquidity') {
      relativePrice = tokenInfo.getDepositPrice();
    } else {
      relativePrice = tokenInfo.getWithdrawPrice();
    }

    loggingTokenPriceInUSD(
      currentAction,
      tokenInfo,
      this.baseToken,
      relativePrice,
      this.priceInUSD,
    );

    return relativePrice.multipliedBy(this.priceInUSD);
  }

  addPriceUSDToken(token: Token) {
    this.priceUSDToken = token;
  }

  /**
   * set LP token (portfolio) total supply
   */
  addTotalSupply(value: BigNumber) {
    this.totalSupply = value;

    loggingPortfolioAddTotalSupply(this);
  }

  /**
   * set user LP token (portfolio) balance
   */
  addBalanceOfWallet(value: BigNumber) {
    this.balanceOfWallet = value;
    this.balanceRestOfWallet = value;

    loggingPortfolioAddBalanceOfWallet(this, value);
  }

  addVolume(value: BigintIsh) {
    this.volume30 = new TokenAmount(this.priceUSDToken, value);
  }

  addFee(value: BigintIsh) {
    this.fee30 = new TokenAmount(this.priceUSDToken, value);
  }

  addPriceInUSD(value: BigNumber) {
    this.priceInUSD = fromWei(value, BOT_USD_DECIMALS);

    loggingPortfolioAddPriceInUSD(this, value);
  }

  /**
   * [R]
   * @param value in relative units
   */
  getValueInUSD(value: string, priceValue?: BigNumber) {
    if (priceValue) {
      return this.priceInUSD.multipliedBy(value).multipliedBy(priceValue);
    }
    return this.priceInUSD.multipliedBy(value);
  }

  /**
   * [R]
   * @param value in relative units
   */
  getValueInBASE(value: string) {
    return new BigNumber(value).dividedBy(this.priceInUSD);
  }

  /**
   * [R] Calculates deposit amount in base token equivalent in relative units
   * @param tokensArray tokens and amounts in relative units
   * @param options calculation options
   */
  getYouWillDeposit(
    tokensArray: { [k: string]: FilledTokensObject },
    options: {
      ignoreBalanceErrors?: boolean;
      usePriceInsteadOfDepositPrice?: boolean;
    } = {},
  ): BigNumber {
    let willDepositBase = new BigNumber(0);
    if (!tokensArray) return willDepositBase;
    if (Object.values(tokensArray).some(token => token.hasError)) {
      if (
        !options.ignoreBalanceErrors ||
        Object.values(tokensArray).some(token => token.hasError && !token.hasOnlyBalanceError)
      )
        return willDepositBase;
    }
    Object.keys(tokensArray).forEach(tokenAddress => {
      const foundToken = this.tokensInfoByAddr[tokenAddress];
      if (foundToken) {
        const depositPrice = options.usePriceInsteadOfDepositPrice
          ? foundToken.price
          : foundToken.getDepositPrice();

        willDepositBase = willDepositBase.plus(
          depositPrice.multipliedBy(tokensArray[tokenAddress].value || 0),
        );
      }
    });
    return willDepositBase;
  }

  /**
   * [R] Calculates deposit amount in base token equivalent in relative units
   * @param willDepositLP [W] value to deposit in LP token wei
   */
  getYouWillDepositBase(willDepositLP: BigNumber): BigNumber {
    const baseDecimals = this.baseToken.decimals;
    // BASE WEI / LP WEI
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> this.lpToken.decimals
    const lpTokenPriceBaseInWei = this.lpTokenPriceBase.shiftedBy(
      baseDecimals - this.lpToken.decimals,
    );
    // LP WEI * (BASE WEI / LP WEI) => BASE WEI
    return BigNumber(willDepositLP).multipliedBy(lpTokenPriceBaseInWei).shiftedBy(-baseDecimals);
  }

  /**
   * [R] Calculates deposit amount in USD equivalent in relative units
   * @param willDepositBase [R] value to deposit in relative base token units
   */
  getYouWillDepositUSD(willDepositBase: BigNumber): BigNumber {
    return toBigNumber(willDepositBase).multipliedBy(this.priceInUSD);
  }

  /**
   * [USD / R] price of one relative lp token unit in USD
   */
  getLpTokenPriceUSD(): BigNumber {
    // (USD / BASE) * (BASE / LP) => USD / LP
    return this.priceInUSD.multipliedBy(this.lpTokenPriceBase);
  }

  updateWithdrawTokenWithoutRecalculation(tokenAddress: string, checked: boolean) {
    this.withdrawTokens[tokenAddress].checked = checked;
  }

  /**
   * [R] user lp tokens balance in relative units
   */
  getBalanceOfLp(): BigNumber {
    // NOTE: Changed `LP_TOKEN_DECIMALS` -> this.lpToken.decimals
    return fromWei(this.balanceOfWallet, this.lpToken.decimals);
  }

  /**
   * [R] user lp balance in relative base token units
   */
  getYouDepositBase(): BigNumber {
    // LP * (BASE / LP) => BASE
    return this.getBalanceOfLp().multipliedBy(this.lpTokenPriceBase);
  }

  /**
   * [R] user lp balance in USD
   */
  getYouDepositUSD(): BigNumber {
    // BASE * (USD / BASE) => USD
    return this.getYouDepositBase().multipliedBy(this.priceInUSD);
  }

  getYouPortfolioShare(): BigNumber {
    if (!this.totalSupply.gt(0)) {
      return new BigNumber(0);
    }

    return this.balanceOfWallet.dividedBy(this.totalSupply).multipliedBy(100);
  }

  /**
   * [R] returns summary withdraw value in base token relative units
   * @param withdrawTokens
   */
  getYouWithdrawInBase(withdrawTokens: { token: Token; amount: BigNumber }[]): BigNumber {
    const baseTokenEquivalentWei = withdrawTokens.reduce((acc, withdrawToken) => {
      const foundToken = this.tokensInfoByAddr[withdrawToken.token.address];
      // BASE WEI / TOKEN WEI
      const withdrawPriceInWei =
        foundToken
          ?.getWithdrawPrice()
          .shiftedBy(this.baseToken.decimals - withdrawToken.token.decimals) ?? BIG_ZERO;
      // TOKEN WEI
      const tokenAmountInWei = withdrawToken.amount.shiftedBy(withdrawToken.token.decimals);
      // (BASE WEI / TOKEN WEI) * TOKEN WEI => BASE WEI
      return acc.plus(withdrawPriceInWei.multipliedBy(tokenAmountInWei));
    }, BIG_ZERO);

    return fromWei(baseTokenEquivalentWei, this.baseToken.decimals);
  }

  /**
   * [R] returns summary withdraw value in base token relative units
   * @param withdrawTokenAmountsWei
   */
  getEstimatedWithdrawInBaseToken(withdrawTokenAmountsWei: string[]): BigNumber {
    const withdrawTokens = Object.values(this.withdrawTokens).filter(token => token.checked);

    const baseTokenEquivalentWei = withdrawTokens.reduce(
      (acc, token, index) =>
        acc.plus(
          token
            .getWithdrawPrice()
            .multipliedBy(withdrawTokenAmountsWei![index])
            .shiftedBy(this.baseToken.decimals - token.token.decimals), // to base decimals
        ),
      BIG_ZERO,
    );

    return fromWei(baseTokenEquivalentWei, this.baseToken.decimals);
  }
}

// DEBUG

function isLoggingPortfoliosDisabled() {
  return !window['BLUESHIFT_DEBUG'].PORTFOLIOS;
}

function loggingInitPortfolio(
  baseToken: Token,
  portfolioInfo: PortfolioDto,
  lpTokenPriceBase: BigNumber,
  totalValue: BigintIsh,
  portfolioTotalValueBase: TokenAmount,
) {
  if (isLoggingPortfoliosDisabled()) return;

  console.log('baseToken: ', `${baseToken.symbol} | ${baseToken.decimals}`);
  console.log(
    `lpTokenPrice (raw) [ (${baseToken.symbol} WEI / LP WEI) * PRICE WEI ] : `,
    ethersToBigNumber(portfolioInfo.lpTokenPrice).toString(),
  );
  console.log(`lpTokenPrice [ ${baseToken.symbol} / LP ] `, lpTokenPriceBase.toString());
  console.log(
    `totalValue (raw) [ ${baseToken.symbol} WEI ] : `,
    ethersToBigNumber(portfolioInfo.totalValue).toString(),
  );
  console.log(`totalValue  [ ${baseToken.symbol} WEI ] : `, totalValue.toString());
  console.log(`totalValue  [ ${baseToken.symbol} ] : `, portfolioTotalValueBase.toFixed());
}

function loggingInitTokenInfo(
  token: Token,
  baseToken: Token,
  targetWeight: BigNumber,
  tokenInfo: PortfolioTokenDto,
  amount: TokenAmount,
  price: BigNumber,
  depositEMAPrice: BigNumber,
  withdrawEMAPrice: BigNumber,
  depositLimit: BigNumber,
  withdrawLimit: BigNumber,
) {
  if (isLoggingPortfoliosDisabled()) return;

  console.groupCollapsed(`[TOKEN INFO: INIT] ${token.symbol} | ${token.decimals} ===`);
  console.log(
    'target weight : ',
    targetWeight.toString(),
    ` | ${targetWeight.multipliedBy(100).toString()}%`,
  );
  console.log('baseToken: ', `${baseToken.symbol} | ${baseToken.decimals}`);
  console.log(
    `amount (raw) [ ${token.symbol} WEI ] : `,
    ethersToBigNumber(tokenInfo.amount).toString(),
  );
  console.log(`amount [ ${token.symbol} ] : `, amount.toFixed());
  console.log(
    `price (raw) [ (${baseToken.symbol} WEI / ${token.symbol} WEI) * PRICE WEI ] : `,
    ethersToBigNumber(tokenInfo.price).toString(),
  );
  console.log(`price [ ${baseToken.symbol} / ${token.symbol} ] : `, price.toString());
  console.log(
    `depositEMAPrice (raw) [ (${baseToken.symbol} WEI / ${token.symbol} WEI) * PRICE WEI ]: `,
    ethersToBigNumber(tokenInfo.depositEMAPrice).toString(),
  );
  console.log(
    `depositEMAPrice [ ${baseToken.symbol} / ${token.symbol} ] : `,
    depositEMAPrice.toString(),
  );
  console.log(
    `withdrawEMAPrice (raw) [ (${baseToken.symbol} WEI / ${token.symbol} WEI) * PRICE WEI ]: `,
    ethersToBigNumber(tokenInfo.withdrawEMAPrice).toString(),
  );
  console.log(
    `withdrawEMAPrice [ ${baseToken.symbol} / ${token.symbol} ] : `,
    withdrawEMAPrice.toString(),
  );
  console.log('depositLimit [ WEI ] : ', depositLimit.toString());
  console.log('withdrawLimit [ WEI ] : ', withdrawLimit.toString());
  console.groupEnd();
}

function loggingPortfolioAddTotalSupply(portfolio: Portfolio) {
  if (isLoggingPortfoliosDisabled()) return;

  console.log(
    `PORTFOLIO [${portfolio.name}] : `,
    `(${portfolio.baseToken.symbol} | ${portfolio.baseToken.decimals}) `,
    `| LP total supply : `,
    portfolio.totalSupply.toString(),
  );
}

function loggingPortfolioAddBalanceOfWallet(portfolio: Portfolio, value: BigNumber) {
  if (isLoggingPortfoliosDisabled()) return;

  console.log(
    `PORTFOLIO [${portfolio.name}] : `,
    `(${portfolio.baseToken.symbol} | ${portfolio.baseToken.decimals}) `,
    `| LP balance of wallet : `,
    value.toString(),
  );
}

function loggingPortfolioAddPriceInUSD(portfolio: Portfolio, value: BigNumber) {
  if (isLoggingPortfoliosDisabled()) return;

  console.log(
    `PRICE API : `,
    `PORTFOLIO [${portfolio.name}] : `,
    `(${portfolio.baseToken.symbol} | ${portfolio.baseToken.decimals}) `,
    `convert by decimals ( ${BOT_USD_DECIMALS} ) `,
    `[ USD token : (${portfolio.priceUSDToken.symbol} | ${portfolio.priceUSDToken.decimals}) ] `,
    ` ->  `,
    value.toString(),
    ` priceInUSD = `,
    portfolio.priceInUSD.toString(),
  );
}

function loggingUpdatePortfolioInfo(
  state: string,
  lpTokenPriceBase: BigNumber,
  totalValue: BigintIsh,
  portfolioTotalValueBase: TokenAmount,
  tokenCount: BigNumber,
  tokens: TokenInfo[],
  crossChainTokens: TokenInfo[],
) {
  if (isLoggingPortfoliosDisabled()) return;

  console.log(`=== ${state} ===`);
  console.log('lpTokenPriceBase : ', lpTokenPriceBase.toString());
  console.log('totalValue : ', totalValue.toString());
  console.log('portfolioTotalValueBase : ', portfolioTotalValueBase.toFixed());
  console.log('tokenCount : ', tokenCount.toString());
  console.log('tokens : ', [...tokens]);
  console.log('crossChain tokens : ', [...crossChainTokens]);
  console.log('=== ===');
}

function isLoggingEasyModeAddLiquidityDisabled() {
  return !window['BLUESHIFT_DEBUG'].EASY_MODE_ADD_LIQUIDITY;
}

function loggingTokenPriceInUSD(
  currentAction: string,
  tokenInfo: TokenInfo,
  baseToken: Token,
  relativePrice: BigNumber,
  priceInUSD: BigNumber,
) {
  if (isLoggingEasyModeAddLiquidityDisabled()) return;

  console.groupCollapsed(
    `[PORTFOLIO] Token [${currentAction}] `,
    `${(tokenInfo.token as any)?.symbol} | ${tokenInfo.token.decimals} `,
    `price in USD ===`,
  );
  console.log('baseToken : ', `${baseToken.symbol} | ${baseToken.decimals}`);
  console.log(
    `${(tokenInfo.token as any)?.symbol} ${currentAction} price in ${baseToken.symbol}: `,
    relativePrice.toString(),
  );
  console.log(`${baseToken.symbol} price In USD : `, priceInUSD.toString());
  console.log(
    `${(tokenInfo.token as any)?.symbol} price In USD : `,
    relativePrice.multipliedBy(priceInUSD).toString(),
  );
  console.groupEnd();
}
