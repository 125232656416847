import { Portfolio } from './portfolio';

export enum PairSourceType {
  EMPTY = 'EMPTY',
  PORTFOLIO = 'PORTFOLIO',
  CROSSCHAIN_PORTFOLIO = 'CROSSCHAIN_PORTFOLIO',
}

export interface PairSource {
  type: PairSourceType;
  address: string;
  name: string;
  id: number | undefined;
  portfolio: Portfolio | undefined;
}
