import { getPortfolioAndPairRegistryContract } from '@/helpers/contract.helper';
import erc20 from '@/data/abi/erc20.json';
import { BN_ZERO, ethersToJSBI, NO_CONTRACT_ADDRESS } from '@/sdk/constants';
import JSBI from 'jsbi';
import { Trade } from '@/sdk/entities/trade';
import multicall from '@/utils/multicall';

import { GLOBAL_GETTERS } from '@/store/modules/global.getters';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';

import blueshiftPair from '@/data/abi/BlueshiftPair.json';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Pair } from '@/sdk/entities/pair';
import { PairSourceType } from '@/sdk/entities/pairSource';

export const ROUTES_ACTION_TYPES = {
  RESET_ROUTES_STATE: 'resetRoutesState',
  GET_PAIRS: 'getPairs',
  GET_PAIRS_WITH_BALANCES: 'getPairsWithBalances',
  CALCULATE_ROUTES_FROM_IN: 'calculateRoutesFromIn',
  CALCULATE_ROUTES_FROM_OUT: 'calculateRoutesFromOut',
  GET_POOL_TOKENS_OWNED: 'getPoolTokensOwned',
};
export const ROUTES_MUTATION_TYPES = {
  SET_STATE: 'setState',
  ADD_PAIR: 'addPair',
};

const state = {
  isPairsLoaded: false,
  rawPairs: {},
  routesInProgress: false,
  pairs: [],
  routes: [],
};
const getters = {
  getBestRouteFromTrades: state => {
    console.log('state', state.routes);
    return state?.routes?.length > 0 ? state.routes[0] : null;
  },
  getOwnedPairs: state => {
    const ownedPairs = state.pairs.filter(pair =>
      JSBI.greaterThan(pair.poolTokensOwned.raw, BN_ZERO),
    );
    return ownedPairs || [];
  },
};
const actions = {
  async [ROUTES_ACTION_TYPES.RESET_ROUTES_STATE]({ commit }) {
    console.warn('[DEPRECATED] ROUTES_ACTION_TYPES.RESET_ROUTES_STATE');
    await new Promise(resolve => {
      const resetState = {
        routesInProgress: false,
        routes: [],
      };
      commit(ROUTES_MUTATION_TYPES.SET_STATE, resetState);
      resolve(true);
    });
  },
  async [ROUTES_ACTION_TYPES.GET_PAIRS]({ commit, rootState }) {
    console.warn('[DEPRECATED] ROUTES_ACTION_TYPES.GET_PAIRS');
    try {
      const arr = Object.keys(rootState.tokens.tokens).map(res => rootState.tokens.tokens[res]);
      const comb = arr.flatMap((tokenA, i) =>
        arr.slice(i + 1).map(tokenB => {
          return tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
        }),
      );
      const dArray = comb.map(combination => [combination[0].address, combination[1].address]);
      if (dArray.length === 0) return [];
      const profileContract = getPortfolioAndPairRegistryContract();
      const addresses = await profileContract.getPairContracts(dArray);
      const rawPairs = {};
      addresses.forEach((address, index) => {
        if (address !== NO_CONTRACT_ADDRESS) {
          rawPairs[address] = comb[index];
        }
      });
      console.log('rawPairs', rawPairs);
      commit(ROUTES_MUTATION_TYPES.SET_STATE, { rawPairs: rawPairs });
      return rawPairs;
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async [ROUTES_ACTION_TYPES.GET_PAIRS_WITH_BALANCES]({ state, commit }, pairs = {}) {
    console.warn('[DEPRECATED] ROUTES_ACTION_TYPES.GET_PAIRS_WITH_BALANCES');

    console.groupCollapsed('ROUTES_ACTION_TYPES.GET_PAIRS_WITH_BALANCES');

    const pairsList = Object.keys(pairs).length > 0 ? pairs : state.rawPairs;
    // commit(ROUTES_MUTATION_TYPES.SET_STATE, { isPairsLoaded: false });
    console.log('pairsList', pairsList);

    const callsDelayedOrders = Object.keys(pairsList).map(pair => ({
      address: pair,
      name: 'getReserves',
    }));
    const rawDelayedOrders = await multicall(blueshiftPair.abi, callsDelayedOrders);
    const callsTotalSupply = Object.keys(pairsList).map(pair => ({
      address: pair,
      name: 'totalSupply',
    }));
    const rawTotalSupply = await multicall(erc20, callsTotalSupply);

    const { walletState } = useEVMWallet();
    const callsPoolTokensOwned = Object.keys(pairsList).map(pair => ({
      address: pair,
      name: 'balanceOf',
      params: [walletState.account],
    }));
    const rawPoolTokensOwned = await multicall(erc20, callsPoolTokensOwned);

    console.log('rawDelayedOrders', rawDelayedOrders);
    console.log('rawTotalSupply', rawTotalSupply);
    console.log('callsPoolTokensOwned', rawPoolTokensOwned);

    Object.keys(pairsList).map((pair, i) => {
      const balance = rawDelayedOrders[i];
      const totalSupply = ethersToJSBI(rawTotalSupply[i]);
      const poolTokensOwned = ethersToJSBI(rawPoolTokensOwned[i]);
      commit(
        ROUTES_MUTATION_TYPES.ADD_PAIR,
        new Pair(
          pair,
          new TokenAmount(pairsList[pair][0].unwrapWETH(), balance._reserve0.toString()),
          new TokenAmount(pairsList[pair][1].unwrapWETH(), balance._reserve1.toString()),
          totalSupply,
          poolTokensOwned,
        ),
      );
    });

    commit(ROUTES_MUTATION_TYPES.SET_STATE, { isPairsLoaded: true });
    console.log('pairs loaded');

    console.groupEnd();
    return Promise.resolve();
  },
  async [ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_OUT](
    { state, commit, rootGetters },
    { tokenA, currencyAmountB, tradeOptions },
  ) {
    console.warn('[DEPRECATED] ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_OUT');
    commit(ROUTES_MUTATION_TYPES.SET_STATE, { routesInProgress: true });

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      let trade: Trade[] = [];
      const portfolioAllPairs = rootGetters[GLOBAL_GETTERS.GET_ALL_PORTFOLIOS_PAIRS];
      const pairs = [...state.pairs, ...portfolioAllPairs];

      if (!pairs.length || !currencyAmountB) {
        commit(ROUTES_MUTATION_TYPES.SET_STATE, { routes: trade, routesInProgress: false });
        return resolve(trade);
      }

      trade = Trade.bestTradeExactOut(pairs, tokenA, currencyAmountB, tradeOptions);

      commit(ROUTES_MUTATION_TYPES.SET_STATE, { routes: trade, routesInProgress: false });
      resolve(trade);
    }).catch(() => {
      commit(ROUTES_MUTATION_TYPES.SET_STATE, { routes: [], routesInProgress: false });
    });
  },
  async [ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_IN](
    { state, commit, rootGetters },
    { currencyAmountA, tokenB, tradeOptions },
  ) {
    console.warn('[DEPRECATED] ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_IN');
    console.log('CALCULATE_ROUTES_FROM_IN', { currencyAmountA, tokenB, tradeOptions });
    commit(ROUTES_MUTATION_TYPES.SET_STATE, { routesInProgress: true });

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      let trade: Trade[] = [];
      const portfolioAllPairs = rootGetters[GLOBAL_GETTERS.GET_ALL_PORTFOLIOS_PAIRS];
      const pairs = [...state.pairs, ...portfolioAllPairs];

      pairs.forEach(pair => {
        console.log(`${pair.token0.symbol}-${pair.token1.symbol}`);
      });

      console.log(
        pairs,
        pairs.filter(pair => pair.pairSource.type === PairSourceType.CROSSCHAIN_PORTFOLIO).length,
      );

      if (!pairs.length || !currencyAmountA) {
        commit(ROUTES_MUTATION_TYPES.SET_STATE, { routes: trade, routesInProgress: false });
        return resolve(trade);
      }

      console.log('start calculate trade');

      trade = Trade.bestTradeExactIn(pairs, currencyAmountA, tokenB, tradeOptions);

      console.log('[CALCULATE_ROUTES_FROM_IN] trade', trade);
      commit(ROUTES_MUTATION_TYPES.SET_STATE, { routes: trade, routesInProgress: false });
      return resolve(trade);
    }).catch(() => {
      commit(ROUTES_MUTATION_TYPES.SET_STATE, { routes: [], routesInProgress: false });
    });
  },
};
const mutations = {
  [ROUTES_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  [ROUTES_MUTATION_TYPES.ADD_PAIR](_state, payload) {
    const pairIndex = _state.pairs.findIndex(pair => pair.tokenAddress === payload.tokenAddress);
    if (pairIndex > -1) {
      _state.pairs[pairIndex] = payload;
      return;
    }
    _state.pairs.push(payload);
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
