import { Percent } from '@/sdk/entities/fractions/percent';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Token } from '@/sdk/entities/token';
import { fromWei, toWei } from '@/sdk/utils';
import BigNumber from 'bignumber.js';
import JSBI from 'jsbi';
import invariant from 'tiny-invariant';

const ZERO = 0n;
const ONE = 1n;

const DECIMALS = 18;

export interface GetSwapOutputParams {
  amplifier: bigint;
  // Token balances of the stable pool
  reserves: TokenAmount[];
  // User input amount
  amount: bigint;
  // The currency user want to swap from
  inputToken: Token;
  // The currency user want to swap to
  outputToken: Token;
  // Fee of swapping
  fee: Percent;
}

export function getSwapOutput({
  amplifier,
  reserves: reserveAmounts,
  inputToken,
  outputToken,
  amount,
  fee,
}: GetSwapOutputParams): TokenAmount {
  const validateAmountOut = (a: TokenAmount) =>
    invariant(!a.lessThan(ZERO), 'Insufficient liquidity to perform the swap');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  amplifier = BigInt(toCompute(amplifier.toString()));

  let i: number | null = null;
  let j: number | null = null;
  const reserves: bigint[] = [];
  for (const [index, r] of reserveAmounts.entries()) {
    reserves.push(BigInt(toCompute(r.toFixed(r.token.decimals))));

    if (r.token.equals(inputToken)) {
      i = index;
      continue;
    }
    if (r.token.equals(outputToken)) {
      j = index;
      continue;
    }
  }

  invariant(
    i !== null && j !== null && i !== j,
    'Input currency or output currency does not match currencies of token balances.',
  );

  // Exact output
  if (amount < 0) {
    const feeAmount = new Percent(1n, 1n).subtract(fee).invert();
    const x = toCompute(fromWei(feeAmount.multiply(amount).toFixed(0), inputToken.decimals));
    const y = getY({ amplifier, reserves, i, j, x });
    const dy = y - reserves[j];
    const amountOut = new TokenAmount(
      outputToken,
      toWei(fromCompute(dy.toString()), outputToken.decimals).toFixed(0),
    );
    validateAmountOut(amountOut);
    return amountOut;
  }

  // Exact input
  const x = toCompute(fromWei(amount.toString(), inputToken.decimals));
  const y = getY({ amplifier, reserves, i, j, x });
  const dy = reserves[j] - y;
  const feeAmount = fee.multiply(dy);
  const dyNumerator = JSBI.subtract(
    JSBI.multiply(JSBI.BigInt(dy.toString()), feeAmount.denominator),
    feeAmount.numerator,
  );
  const amountOutWei = JSBI.divide(dyNumerator, feeAmount.denominator);
  const amountOut = new TokenAmount(
    outputToken,
    toWei(fromCompute(amountOutWei.toString()), outputToken.decimals).toFixed(0),
  );
  validateAmountOut(amountOut);
  return amountOut;
}

export function getSwapInput({ amount, ...rest }: GetSwapOutputParams) {
  return getSwapOutput({
    ...rest,
    amount: -amount,
  });
}

interface GetYParams {
  amplifier: bigint;
  reserves: bigint[];
  // The index of the base token
  i: number;
  // The index of the swap target token
  j: number;
  // The amount of token i that user deposit
  x: string;
}

/**
 * Calculate the expected token amount y after user deposit
 * @see https://classic.curve.fi/files/stableswap-paper.pdf
 */
function getY({ amplifier, reserves, i, j, x }: GetYParams): bigint {
  const numOfCoins = reserves.length;
  invariant(numOfCoins > 1, 'To get y, pool should have at least two coins.');
  invariant(
    i !== j && i >= 0 && j >= 0 && i < numOfCoins && j < numOfCoins,
    `Invalid i: ${i} and j: ${j}`,
  );

  const n = BigInt(numOfCoins);
  const d = getD({ amplifier, reserves });
  let sum = ZERO;
  let c = d;
  // The amplifier is actually An^n-1, so we only times n here
  const ann = BigInt(amplifier) * n;
  for (const [index, r] of reserves.entries()) {
    if (index === j) {
      continue;
    }
    let reserveAfterDeposit = BigInt(r);
    if (index === i) {
      reserveAfterDeposit += BigInt(x);
    }

    invariant(reserveAfterDeposit > ZERO, 'Insufficient liquidity');

    sum += reserveAfterDeposit;
    c = (c * d) / (reserveAfterDeposit * n);
  }
  c = (c * d) / (ann * n);
  const b = sum + d / ann;

  // Equality with the precision of 1
  const precision = ONE;
  let yPrev = ZERO;
  let y = d;
  for (let k = 0; k < 255; k += 1) {
    yPrev = y;
    y = (y * y + c) / (2n * y + b - d);

    if (y > yPrev && y - yPrev <= precision) {
      break;
    }

    if (y <= yPrev && yPrev - y <= precision) {
      break;
    }
  }

  return y;
}

interface Params {
  amplifier: bigint;
  reserves: bigint[];
}

/**
 * Calculate the constant D of Curve AMM formula
 * @see https://classic.curve.fi/files/stableswap-paper.pdf
 */
function getD({ amplifier, reserves }: Params): bigint {
  const numOfCoins = reserves.length;
  invariant(numOfCoins > 1, 'To get constant D, pool should have at least two coins.');

  const sum = reserves.reduce<bigint>((s, cur) => s + BigInt(cur), ZERO);
  if (sum === ZERO) {
    return ZERO;
  }

  const n = BigInt(numOfCoins);
  // Equality with the precision of 1
  const precision = ONE;
  // The amplifier is actually An^n-1, so we only times n here
  const ann = BigInt(amplifier) * n;
  let dPrev = ZERO;
  let d = sum;
  for (let i = 0; i < 255; i += 1) {
    let dp = d;
    for (const r of reserves) {
      dp = (dp * d) / (BigInt(r) * n + 1n);
    }
    dPrev = d;
    // d = ((ann * sum + dp * n) * d) / ((ann - ONE) * d + (n + ONE) * dp);
    d = ((ann * sum + dp * n) * d) / ((ann - ONE) * d + (n + ONE) * dp);

    if (d > dPrev && d - dPrev <= precision) {
      break;
    }

    if (d <= dPrev && dPrev - d <= precision) {
      break;
    }
  }

  return d;
}

function toCompute(value: string | BigNumber): string {
  return toWei(value, DECIMALS).toFixed(0);
}

function fromCompute(value: string | BigNumber): BigNumber {
  return fromWei(value, DECIMALS);
}
